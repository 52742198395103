/**
 * Unified interface for serialization and deserialization.
 * This prepares us for different deserialization methods in the future (e.g. MsgPack).
 */
export interface ISerializer {
	serialize(obj: any, replacer?: ((this: any, key: string, value: any) => any) | undefined): any;
	deserialize(obj: any, reviver?: ((this: any, key: string, value: any) => any) | undefined): any;
	addIgnoredField(fieldName: string): void;
	removeIgnoredField(fieldName: string): void;
}

export class SerializerJson implements ISerializer {
	serialize = (obj: any, replacer?: ((this: any, key: string, value: any) => any) | undefined) => {
		if (!replacer) {
			replacer = this.DefaultReplacer;
		}
		return JSON.stringify(obj, replacer);
	};

	deserialize = (obj: any, reviver?: ((this: any, key: string, value: any) => any) | undefined) => {
		if (!reviver) {
			reviver = this.DefaultReplacer;
		}
		return JSON.parse(obj, reviver);
	};

	addIgnoredField = (fieldName: string): void => {
		if (this.IgnoredFields.includes(fieldName)) return;

		this.IgnoredFields.push(fieldName);
	};

	removeIgnoredField = (fieldName: string): void => {
		const index = this.IgnoredFields.indexOf(fieldName);
		if (0 <= index) {
			this.IgnoredFields.splice(index, 1);
		}
	};

	private DefaultReplacer = (key: string, value: any) => {
		if (this.IgnoredFields.includes(key)) {
			return undefined;
		}
		return value;
	};
	private readonly IgnoredFields = ['_dataObjCached'];
}

export const DefaultSerializer: ISerializer = new SerializerJson();
