import { LogFactory } from '../../src-ts/common/Log';

export function addTouchHandlesForMouse() {
	// 20230419 Mobile Chromium-based browsers (inc. Chrome) ceased responding to `ontouchend` within HTML!
	//   To fix, found could add `onmouseup` handler instead!
	//   To remove duplicate messages, had to remove the `ontouchend` handler.
	Log.debug('addTouchHandlesForMouse');
	const elements = document.getElementsByTagName('*');
	Log.debug(`addTouchHandlesForMouse() found ${elements.length} elements`);
	let numStart = 0,
		numEnd = 0;
	for (let i = 0; i < elements.length; ++i) {
		const element = elements[i];
		const ontouchstart = element.getAttribute('ontouchstart');
		if (ontouchstart) {
			numStart++;
			element.setAttribute('onmousedown', ontouchstart);
		}
		const ontouchend = element.getAttribute('ontouchend');
		if (ontouchend) {
			numEnd++;
			// 20230419 Removed `ontouchend` to remove duplicated events
			element.removeAttribute('ontouchend');
			element.setAttribute('onmouseup', ontouchend);
		}
	}
	Log.debug(`addTouchHandlesForMouse() found ${numStart} touchStart and ${numEnd} touchEnd`);
}

const Log = LogFactory.build('utils');
