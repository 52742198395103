// TODO: Consider making this a facade/proxy/replacing-with ng2-logger or typescript-logging?

import { Colour, ColourHelperTerminal } from './ColourHelperTerminal';
import { ColourHelperBrowser } from './ColourHelperBrowser';
import { extractValueFromEnvVarNamed } from '../utils/EnvVars';
import { toString } from '../server/utils/JsonUtils';

export enum LogLevel {
	All = 0,
	Silly = 0,
	Debug = 1,
	Default = 2,
	Log = 2,
	Production = 3,
	Warn = 3,
	Error = 4,
	None = 5,
}

export function getLogLevelFromString(logLevelString: string): LogLevel | undefined {
	const keys: string[] = Object.keys(LogLevel);

	for (const key of keys) {
		const value: any = LogLevel[<any>key];

		if ('number' === typeof value && key === logLevelString) {
			return value as LogLevel;
		}
	}

	return undefined;
}

const LogEnvVarName: string = 'MFG_LOG';
const LogEnvValueDelimiter: string = '-';
type LogResults = {
	level: LogLevel;
	flags: string[];
};

function getDefaultLevel(loggerName?: string): LogResults {
	return getDefaultLevelNoDefault(loggerName) ?? defaultDefaultLevel;
}

function getFlags(value: string, delim: string): { flags: string[]; valueWithoutFlags: string } {
	// Check for, use and consume any flags
	let flags: string[] = [];
	let pos1: number = 0;
	let valueWithoutFlags: string | undefined;
	while (-1 !== (pos1 = value.indexOf(delim, pos1))) {
		if (undefined === valueWithoutFlags) {
			// first search is end of valueWithoutFlags
			valueWithoutFlags = value.substring(0, pos1);
		}
		const pos2 = value.indexOf(delim, pos1 + 1);
		const flag = -1 === pos2 ? value.substring(pos1 + 1) : value.substring(pos1 + 1, pos2);

		flags.push(flag);
		// Move next-search start pos up to previously found / end of string
		pos1 = -1 === pos2 ? value.length : pos2 - 1;
	}
	return { flags, valueWithoutFlags: undefined === valueWithoutFlags ? value : valueWithoutFlags };
}

function getDefaultLevelNoDefault(loggerName?: string): LogResults | undefined {
	if (undefined !== loggerName) {
		const levelFromEnv = extractValueFromEnvVarNamed(LogEnvVarName, loggerName);
		if (undefined !== levelFromEnv) {
			const { flags, valueWithoutFlags } = getFlags(levelFromEnv, LogEnvValueDelimiter);
			const maybeLevel: LogLevel | undefined = getLogLevelFromString(valueWithoutFlags);
			if (undefined !== maybeLevel) {
				return { level: maybeLevel, flags };
			}
		}
	}
	return undefined;
}

let defaultDefaultLevel: LogResults = getDefaultLevelNoDefault('Default') ?? { level: LogLevel.Default, flags: [] };

export interface SimpleLogger {
	/**
	 * Log a message.  Will expand and indent arguments as appropriate (including functions that return strings or arrays).
	 */
	log(...args: any[]): void;

	/**
	 * Log a warning.  Will expand and indent arguments as appropriate (including functions that return strings or arrays).
	 */
	warn(...args: any[]): void;

	/**
	 * Log an error.  Will expand and indent arguments as appropriate (including functions that return strings or arrays).
	 */
	error(...args: any[]): void;
}

export interface MediumLogger extends SimpleLogger {
	/**
	 * Log super-detailed information.
	 * Best to supply a closure (that returns a string or array) so not evaluated to string if not enabled.
	 */
	silly(...args: any[]): void;

	/**
	 * Log detailed information.
	 * Best to supply a closure (that return a string or array) so not evaluated to string if not enabled.
	 */
	debug(...args: any[]): void;
}

export interface Logger extends MediumLogger {
	/**
	 * Call the log function directly on the Logger.
	 * I.e. `logger(LogLevel.Error, "my log message");`
	 */
	(level: LogLevel, ...args: any[]): void;

	/**
	 * Log the output of the function *only* if the level is being logged.
	 * Good for debug which requires lots of processing unneeded otherwise.
	 */
	logf(level: LogLevel, logFunction: LogFunction): void;

	readonly loggerName?: string;

	/**
	 * Set the logging level differently than that supplied at construction.
	 * See also LogFactory.setLevel() which does by 'loggerName'.
	 */
	setLevel(newLevel: LogLevel): Logger;

	/**
	 * Get the logging level differently than that supplied at construction.
	 * See also LogFactory.setLevel() which does by 'loggerName'.
	 */
	getLevel(): LogLevel;

	/**
	 * Reset the logging level back to that supplied at construction.
	 * See also LogFactory.resetLevels() which does all.
	 */
	resetLevel(): Logger;

	/**
	 * Set whether to show calling stack below log entries.
	 */
	setShowStacks(show: boolean): void;
}

export type LogFunction = () => string;

export class LogFactory {
	public static build(loggerName?: string, defaultLevel: LogLevel = LogLevel.Default): Logger {
		const nameToCheck = loggerName ?? '';
		const registeredLogger = loggers.get(nameToCheck);
		if (registeredLogger) {
			// Could check default level being changed here and allow replacing
			return registeredLogger;
		}

		let showTimeStamps = LogFactory.defaultShowTimestamps;
		let showStacks = LogFactory.defaultShowStacks;
		if (LogLevel.Default === defaultLevel) {
			const levelAndFlags = getDefaultLevel(loggerName);
			defaultLevel = levelAndFlags.level;
			for (const flag of levelAndFlags.flags) {
				switch (flag) {
					case 'T':
						showTimeStamps = true;
						break;

					case 'S':
						showStacks = true;
						break;

					// ignore unknown flags?
				}
			}
		}
		const instance = new LogFactory(loggerName, defaultLevel);
		if (showTimeStamps) {
			instance.showTimestamps = true;
		}

		if (showStacks) {
			instance.setShowStacks(true);
		}

		// Creates a new function to allow direct call then map on all other functions of instance via second argument
		const newLogger: Logger = Object.assign((level: LogLevel, ...args: any[]) => instance.logByLevel(level, ...args), {
			// TODO: Map the multi-argument direct-call function too.
			log: mappedLog,
			silly: mappedSilly,
			debug: mappedDebug,
			warn: mappedWarn,
			error: mappedError,
			resetLevel: (): Logger => {
				instance.resetLevel();
				return newLogger;
			},
			logf: (level: LogLevel, logFunction: LogFunction): void => instance.logf(level, logFunction),
			/* Abortive attempt to use accessors on the underlying field (see below)
			get level() {
				return instance.level;
			},
			set level(newVal: LogLevel) {
				instance.level = newVal;
			},
			*/
			getLevel: (): LogLevel => instance.getLevel(),
			setLevel: (newLevel: LogLevel): Logger => {
				instance.setLevel(newLevel);
				return newLogger;
			},
			setShowStacks: instance.setShowStacks,
			// 'name' is a JS function special value so we cannot overwrite it!
			loggerName: instance.loggerName, // read-only so just assigned
		});

		function mappedLog(...args: any[]): void {
			return instance.logWithColourAndPrefix(
				LogLevel.Log,
				console.log,
				Colour.Unspecified,
				'NORMAL:',
				mappedLog,
				...args
			);
		}

		function mappedSilly(...args: any[]): void {
			return instance.logWithColourAndPrefix(
				LogLevel.Silly,
				console.debug,
				Colour.Cyan,
				'SILLY:',
				mappedSilly,
				...args
			);
		}

		function mappedDebug(...args: any[]): void {
			return instance.logWithColourAndPrefix(
				LogLevel.Debug,
				console.debug,
				Colour.Blue,
				'DEBUG:',
				mappedDebug,
				...args
			);
		}

		function mappedWarn(...args: any[]): void {
			return instance.logWithColourAndPrefix(LogLevel.Warn, console.warn, Colour.Yellow, 'WARN:', mappedWarn, ...args);
		}

		function mappedError(...args: any[]): void {
			return instance.logWithColourAndPrefix(LogLevel.Error, console.error, Colour.Red, 'ERROR:', mappedError, ...args);
		}

		loggers.set(nameToCheck, newLogger);
		if (LogLevel.Default !== defaultLevel) {
			console.log(loggerName, 'logging at level', LogLevel[defaultLevel]);
		}
		return newLogger;
	}

	/**
	 * Set the Logging level for a given loggerName.
	 * @param loggerName The logger loggerName or '' for the default.
	 * @param level The new logging level.
	 */
	public static setLevel(loggerName: string, level: LogLevel): void {
		const registeredLogger = loggers.get(loggerName);
		if (registeredLogger) {
			registeredLogger.setLevel(level);
			return;
		}

		// No logger yet registered with that name.
		// Instead of ignoring, assume we wish to set that level for future use.
		// This does mean its default level will be the default and cannot be overridden
		const newLogger = this.build(loggerName);
		newLogger.setLevel(level);
	}

	/**
	 * Set whether to show the call stacks for a given loggerName.
	 * @param loggerName The logger loggerName or '' for the default.
	 * @param show Whether to show stacks for this logger.
	 */
	public static setShowStacks(loggerName: string, show: boolean): void {
		const registeredLogger = loggers.get(loggerName);
		if (registeredLogger) {
			registeredLogger.setShowStacks(show);
			return;
		}

		// No logger yet registered with that name.
		// Instead of ignoring, assume we wish to set value for future use.
		const newLogger = this.build(loggerName);
		newLogger.setShowStacks(show);
	}

	/**
	 * Set the default level all new loggers will be created with and update all loggers to this level.
	 */
	public static setDefaultDefaultLevelAndAllExistingLevels(level: LogLevel): void {
		defaultDefaultLevel.level = level;
		loggers.forEach((logger) => logger.setLevel(level));
	}

	/**
	 * Set the default for whether to show stacks (also updates all existing loggers).
	 */
	public static setDefaultShowStacksAndUpdateAllExisting(show: boolean): void {
		LogFactory.defaultShowStacks = show;
		loggers.forEach((logger) => logger.setShowStacks(show));
	}

	// Ended-up with getLevel() and setLevel() rather than public variable to
	// work-around scope issues after combining via Object.assign().
	// Before this, the functions used the original 'instance' value but the
	// setter assigned the 'outer' value (from assign()) which then had no effect.
	// Tried using accessors in the mapped object but also failed.
	// This likely relates to use of arrow vs. functions but was low priority to solve better.

	public getLevel = (): LogLevel => this.level;

	public setLevel = (newLevel: LogLevel): void => {
		this.level = newLevel;
	};

	public static resetLevels(): void {
		loggers.forEach((logger) => logger.resetLevel());
	}

	public static resetLevelsAndStacks(): void {
		loggers.forEach((logger) => {
			logger.resetLevel();
			logger.setShowStacks(LogFactory.defaultShowStacks);
		});
	}

	public setShowStacks = (show: boolean): void => {
		this.showStack = show;
	};

	private constructor(public readonly loggerName?: string, public level: LogLevel = LogLevel.All) {
		this.defaultLevel = level;
	}

	public logByLevel = (level: LogLevel, ...args: any[]): void => {
		if (level < this.level) return;
		if (LogLevel.Error <= level) {
			this.logWithColourAndPrefix(LogLevel.Error, console.error, Colour.Red, 'ERROR:', this.logByLevel, ...args);
		} else if (LogLevel.Warn === level) {
			this.logWithColourAndPrefix(LogLevel.Warn, console.warn, Colour.Yellow, 'WARN:', this.logByLevel, ...args);
		} else if (LogLevel.Debug === level) {
			this.logWithColourAndPrefix(LogLevel.Debug, console.debug, Colour.Blue, 'DEBUG:', this.logByLevel, ...args);
		} else if (LogLevel.Silly === level) {
			this.logWithColourAndPrefix(LogLevel.Silly, console.debug, Colour.Cyan, 'SILLY:', this.logByLevel, ...args);
		} else {
			this.logWithColourAndPrefix(LogLevel.Log, console.log, Colour.Unspecified, 'NORMAL:', this.logByLevel, ...args);
		}
	};

	public log = (...args: any[]): void =>
		this.logWithColourAndPrefix(LogLevel.Log, console.debug, Colour.Unspecified, 'NORMAL:', this.log, ...args);

	public normal = (...args: any[]): void =>
		this.logWithColourAndPrefix(LogLevel.Log, console.debug, Colour.Unspecified, 'NORMAL:', this.normal, ...args);

	public silly = (...args: any[]): void =>
		this.logWithColourAndPrefix(LogLevel.Silly, console.debug, Colour.Cyan, 'SILLY:', this.silly, ...args);

	public debug = (...args: any[]): void =>
		this.logWithColourAndPrefix(LogLevel.Debug, console.debug, Colour.Blue, 'DEBUG:', this.debug, ...args);

	public warn = (...args: any[]): void =>
		this.logWithColourAndPrefix(LogLevel.Warn, console.warn, Colour.Yellow, 'WARN:', this.warn, ...args);

	public error = (...args: any[]): void =>
		this.logWithColourAndPrefix(LogLevel.Error, console.error, Colour.Red, 'ERROR:', this.error, ...args);

	private logWithColourAndPrefix = (
		level: LogLevel,
		logActual: (...args: any[]) => void,
		colour: Colour,
		prefix: string,
		topLevelLogFunction: any,
		...args: any[]
	): void => {
		if (level < this.level) return;

		let stack: string | undefined;
		if (this.indentWithStack || this.showStack) {
			const error = new Error('(stack)');

			if (Error.captureStackTrace) {
				if (!topLevelLogFunction) topLevelLogFunction = this.logWithColourAndPrefix;
				// omit beyond this from the stack
				Error.captureStackTrace(error!, topLevelLogFunction);
			}

			if (error.stack) {
				const StackPrefixLength = 15;
				stack = error!.stack!.substring(StackPrefixLength);
			} else {
				// In future, do not bother trying to use stack
				this.showStack = false;
				this.indentWithStack = false;
			}
		}

		let indent = '';
		if (this.showTimestamps) {
			// Stuff the timestamp in the indent
			indent = indent + LogFactory.getTimeStamp() + ' ';
		}

		if (stack && this.indentWithStack) {
			const splitResult = stack.split('\n');
			const filtered = splitResult.filter((s) => !LogFactory.IndentFilterRegex.test(s));
			const depth = filtered.length - 1; // there's always 1 element in stack
			indent = indent + ' '.repeat(depth);
			if (LogFactory.FilterOthersCodeFromStacks) {
				stack = filtered.join('\n');
			}
		}

		LogFactory.replaceObjectsWithToString(args, indent);
		if ('undefined' === typeof window) {
			// Node
			const prefixColour = ColourHelperTerminal.getColourCode(colour);
			const suffixColour = ColourHelperTerminal.getReset();
			this.loggerName
				? logActual(prefixColour + indent + this.loggerName, prefix, ...args, suffixColour)
				: logActual(prefixColour + indent + prefix, ...args, suffixColour);
		} else {
			// Browser
			const colourData = ColourHelperBrowser.getColourCode(colour);
			this.loggerName
				? logActual('%c' + indent + this.loggerName + ' ' + prefix + ' ' + args.join(' '), colourData)
				: logActual('%c' + indent + prefix + ' ' + args.join(' '), colourData);
		}

		if (stack && this.showStack) logActual(stack, '\n');
	};

	public logf = (level: LogLevel, logFunction: LogFunction): void => {
		if (level < this.level) return;
		this.logByLevel(level, logFunction());
	};

	public resetLevel = (): void => {
		this.level = this.defaultLevel;
	};

	public static replaceObjectsWithToString(args: any[], indent?: string, recursion: number = 10): any[] {
		for (let i = 0; i < args.length; i++) {
			switch (typeof args[i]) {
				case 'undefined':
				case 'string':
				case 'number':
				case 'boolean':
					break;

				case 'function':
					try {
						args[i] = args[i]();
						if (0 < recursion && Array.isArray(args[i])) {
							const result = this.replaceObjectsWithToString(args[i], indent, recursion - 1);
							args[i] = result.join(' ');
						}
					} catch {
						// nop = we can but try
					}
					break;

				case 'object':
				default:
					args[i] = toString(args[i]);
					break;
			}
		}

		// Do indents
		if (indent) {
			indent = '\n' + indent;
			for (let i = 0; i < args.length; i++) {
				if (args[i] instanceof String || 'string' === typeof args[i]) {
					args[i] = args[i].replaceAll('\n', indent);
					// } else {
					// 	args.splice(i + 1, 0, '' + typeof args[i] + `(${args[i]})`);
					// 	i++;
				}
			}
		}
		return args;
	}

	private static getTimeStamp(): string {
		const date = new Date();
		const [year, month, day, hour, minutes, seconds] = [
			date.getFullYear(),
			date.getMonth() + 1,
			date.getDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds(),
		];

		function n(num: number): string {
			return num.toString().padStart(2, '0');
		}

		return `${year}${n(month)}${n(day)}-${n(hour)}${n(minutes)}${n(seconds)}`;
	}

	private indentWithStack: boolean = true;

	private showStack: boolean = LogFactory.defaultShowStacks;

	private showTimestamps: boolean = LogFactory.defaultShowTimestamps;

	private readonly defaultLevel: LogLevel;

	private static readonly IndentFilterRegex =
		/modules|runMicrotasks|processTicksAndRejections|Log\.ts|at Module\.|at require\./;

	private static readonly FilterOthersCodeFromStacks = true;

	private static defaultShowTimestamps: boolean = false;

	private static defaultShowStacks: boolean = false;
}

// Maps from 'loggerName' to list of loggers registered with that loggerName
const loggers = new Map<string, Logger>();

// Rather than changing default log level, set environmental variable "MFG_LOG" with the form:
// "MyClass=Silly,ClassThatShowsTimeStamps=Log-T,ClassWithStacks=Log-S,Default=Warn"
// Appending "-S" will show stacks with logs; Appending "-T" will show timestamps; or use both.
const Log: Logger = LogFactory.build(); // or build(undefined, LogLevel.All);
export default Log;
