// See `RK-Working-Notes/20220511-ServerAWSConversationNotes.md` for summary table.

export interface IDatabaseRecord {
	/**
	 * Primary key.
	 * GameRecord : it is the join code.
	 * DeviceRecord : it is the deviceId (both controllers and game).
	 */
	id: JoinCodeOrDeviceId;

	/**
	 * Used to distinguish between types of entries.
	 */
	type: RecordType;

	/**
	 * When the entry should be GC'd by the backing storage.
	 * Updated every message that interacts with this device or game.
	 * DynamoDB requires it be expressed as UNIX epoch time in *seconds* whereas the JavaScript API returns *milliseconds*!
	 * See DynamoDB's [TTL service](https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/TTL.html).
	 */
	ttl: TimeStamp;

	/**
	 * Increased by 1 when record updated.  Used to determine whether version being committed is up-to-date or a new load and save is needed.
	 */
	revision: number;

	// Disabled for now.  Was considering as global secondary index.  See `RK-Working-Notes/20220511-ServerAWSConversationNotes.md`
	// /**
	//  * Last ConnectionId device was seen on.
	//  */
	// connectionId: ConnectionId;
}

export enum RecordType {
	/** A device that has not yet declared its type.  Used when devices first connect. */
	NotYetKnown = 0,

	/** A Game (not a device).  Id is join code. */
	Game = 1,

	/** A controller. */
	ControllerDevice = 2,

	/** The connection of a game. */
	GameDevice = 3,

	/**
	 * ConnectionId to DeviceId redirect (because they differ).
	 * Happens when a device disconnects and reconnects.
	 * Before disconnect, the connectionId and deviceId match (so it's fast to query).
	 * After reconnect, this new record redirects us from the new connectionId to the updated deviceId.
	 * N.b. Should not be set for `deviceId`s.
	 */
	RedirectConnectionIdToDeviceId = 4,

	/**
	 * Server-sent message id numbers are kept distinct by recording last used one in this field.
	 * @see MessageIdFactoryServer
	 */
	ServerMessageIdRecord = 5,
}

/**
 * Epoch time.
 * DynamoDB requires it be expressed in *seconds* whereas the JavaScript API returns *milliseconds*!
 */
export type TimeStamp = number;

/**
 * Added to `timeNowInUNIXSeconds()` on message receipt.
 * @see timeNowInUNIXSeconds
 */
export const TtlDefault: number = 60 * 60 * 24 * 5; // 432000; // 5 days.

/**
 * Time (in *SECONDS*) we allow for and pong to be received after a ping is sent.
 * @see timeNowInUNIXSeconds
 * @see checkDeviceIsReallyConnected
 */
export const PingPongTimeoutSeconds: number = 10;

/** Pings may not be sent more often than this. */
export const PingPongNotTooOftenSeconds: number = 60;

export function timeNowInUNIXSeconds(roundDown: boolean = true): number {
	const raw = Date.now() / 1000;
	return roundDown ? Math.floor(raw) : Math.ceil(raw);
}

export function buildTtlFromNow(roundDown: boolean = true): number {
	const raw = timeNowInUNIXSeconds() + TtlDefault;
	return roundDown ? Math.floor(raw) : Math.ceil(raw);
}

export function buildPongTimeoutEpochSecondsFromNow(pingPongTimeoutSeconds: number): number {
	// Important to round *BOTH* (now and total) *up* so fractional pingPongTimeoutSeconds will be more than zero!
	return Math.ceil(timeNowInUNIXSeconds(false) + pingPongTimeoutSeconds);
}

/**
 * Type of either DeviceId (currently `string`) and JoinCode (currently `number`).
 */
export type JoinCodeOrDeviceId = string;

export function convertJoinCodeOrDeviceIdToJoinCode(joinCodeOrDeviceId: unknown): number {
	if (!joinCodeOrDeviceId) throw 'Null joinCodeOrDeviceId';

	if ('number' === typeof joinCodeOrDeviceId) return joinCodeOrDeviceId;

	if ('string' === typeof joinCodeOrDeviceId) {
		const num = parseInt(joinCodeOrDeviceId);
		if (isNaN(num)) throw `Failed to convert to number, joinCodeOrDeviceId:"${joinCodeOrDeviceId}"`;

		// noinspection ConstantOnRightSideOfComparisonJS -- easier to read this way round
		if (0 <= num || num <= 9999) return num;

		throw `Failed to convert to valid join code number (0000-9999), joinCodeOrDeviceId:"${joinCodeOrDeviceId}"`;
	}

	throw `Failed to convert to join code, joinCodeOrDeviceId:"${joinCodeOrDeviceId}"`;
}

export function convertJoinCodeOrDeviceIdToDeviceId(joinCodeOrDeviceId: unknown): string {
	if (!joinCodeOrDeviceId) throw 'Null joinCodeOrDeviceId';

	if ('string' === typeof joinCodeOrDeviceId) return joinCodeOrDeviceId;

	throw `Failed to convert to device id, joinCodeOrDeviceId:"${joinCodeOrDeviceId}"`;
}
