import * as util from 'util';

export function fromJSON(s: string): any {
	return JSON.parse(s);
}

/**
 * Convert `o` to JSON and handle
 * @param o The object to represent.
 * @param pretty Whether to format nicely (vs. being more compact).
 */
export function toJson(o: unknown, pretty?: boolean): string {
	const json = JSON.stringify(o, undefined, pretty ? 2 : undefined);
	return '{}' !== json ? json : '' + o;
}

/**
 * Turn an `unknown` into a string in as smart a way as possible.
 */
export function toString(o: unknown, pretty?: boolean): string {
	if ('string' === typeof o) return o;

	// Non-null object with own `toString()`, use that
	if (o && 'object' === typeof o && o.hasOwnProperty('toString')) {
		const toStringResult = '' + o;
		if (
			toStringResult &&
			0 !== toStringResult.length &&
			'{}' !== toStringResult &&
			'[object Object]' !== toStringResult
		)
			return toStringResult;
	}

	// Otherwise try JSON
	try {
		const json = JSON.stringify(o, undefined, pretty ? 2 : undefined);
		if ('{}' !== json && '[{}]' !== json) {
			return json;
		}
	} catch {} // JSON can fail if circular so ignore if that happens

	if (o instanceof Event) {
		return eventToString(o);
	}

	// if JSON is 'empty', try system default in case any better.
	// const defaultToString = '' + o;
	const defaultToString = util.inspect(o);
	return 0 !== defaultToString.length ? defaultToString : '{}';
}

export function eventToString(e: Event | CloseEvent, pretty: boolean = false): string {
	let NL = '',
		NLS = '',
		CNL = ', ',
		CNLS = ', ',
		FS = ': ';
	if (pretty) {
		// newline
		NL = '\n';
		// newline + space
		NLS = '\n  ';
		// comma + newline
		CNL = ',\n';
		// comma + newline + space
		CNLS = ',\n  ';
		// field-value separator + space
		FS = ':\t';
	}

	let s = 'constructor' in e && 'name' in e.constructor ? e.constructor.name + ' ' : '';
	s += '{' + NLS;
	function addMember(name: string) {
		if (name in e) {
			s += `${CNLS}${name}${FS}${(<any>e)[name]}`;
		}
	}

	// First line = no comma
	s += `type${FS}${e.type}`;

	// WebSocket CloseEvent
	addMember('code');
	addMember('reason');
	addMember('wasClean');

	// WebSocket ErrorEvent
	addMember('message');
	addMember('error');

	// Hack of last ','
	// return s.substring(0, s.length - 1 - CNLS.length) + '}';
	return s + NL + '}';
}
