import IMessage, { isIMessage } from './IMessage';
import MsgClientSentBase from './MsgClientSentBase';
import MsgBase from './MsgBase';
import MsgDisconnectDevicePermanentlyResponse from './MsgDisconnectDevicePermanentlyResponse';
import { ResponseCode } from './ResponseCode';
import { ConnectionId } from './ConnectionId';
import { IMessageRunContext } from '../../server/IRoutingServer';
import MsgResponseBase from './MsgResponseBase';
import { PostToConnection } from 'aws-lambda-ws-server';
import { LogFactory } from '../Log';
import DeviceRecord from '../../server/model/DeviceRecord';
import { Game } from '../../server/model/Game';
import MsgInfoFromServerControllerDisconnected from './MsgInfoFromServerControllerDisconnected';
import MsgInfoFromServer from './MsgInfoFromServer';
import { ServerInfoCode } from './ServerInfoCode';
import { CommandValue } from './Command';

export interface IMsgRemoveDevice extends IMessage {}

/**
 * Permanently disconnect a device, removing all references and resources.
 * Disconnects controllers from any game it is a member of.
 * Disconnects a game and all controllers if sent by a game.
 */
export default class MsgDisconnectDevicePermanently
	extends MsgClientSentBase<MsgDisconnectDevicePermanentlyResponse>
	implements IMsgRemoveDevice
{
	public readonly command = CommandValue.RemoveDevice;

	public constructor(msgId: number = MsgBase.MsgIdAuto) {
		super(msgId);
	}

	public static isMsgDisconnectDevicePermanently(o: any): o is MsgDisconnectDevicePermanently {
		return isIMessage(o) && CommandValue.RemoveDevice === o.command;
	}

	public static buildFromIMessage(o: IMessage): MsgDisconnectDevicePermanently {
		const withMethods = new MsgDisconnectDevicePermanently();
		Object.assign(withMethods, o); // copy `o` over withMethods
		return withMethods;
	}

	/**
	 * Permanently disconnect a device, tidying-up all records and informing peers of removal.
	 */
	public run = async (
		connectionId: ConnectionId,
		context: IMessageRunContext,
		postToConnection: PostToConnection
	): Promise<MsgResponseBase> => {
		try {
			Log.debug(`[${this.msgId}] Permanently disconnecting ConnectionId:${connectionId}`);
			await context.operations.doConnectionClosure(
				connectionId,
				postToConnection,
				async (device: DeviceRecord, game: Game): Promise<void> => {
					// Controller
					Log.debug('Inform Game that a controller disconnected using MsgInfoFromServer');
					const msg = new MsgInfoFromServerControllerDisconnected(
						device.id,
						true,
						`Controller disconnected ${device} permanently`
					);
					const result = await context.outbound.sendToDeviceIds([game.gameDeviceId], msg, postToConnection);
					if (ResponseCode.Success !== result.responseCode) {
						Log.error('Failed to send controller', device, 'disconnect message to game', game);
					}
					// Remove from game -- is actually done by deleteDeviceId() so skip redoing
					// await this.backingStore.removeDeviceFromGame(game.joinCode, deviceId);
					await context.backingStore.deleteDevice(device.id);
				},
				async (device: DeviceRecord, game: Game): Promise<void> => {
					// Game
					if (0 !== game.controllerDeviceIds.length) {
						Log.debug('Inform controllers that Game has disconnected using MsgInfoFromServer');
						const msg = new MsgInfoFromServer(
							ServerInfoCode.GameDisconnectedPermanently,
							'Game disconnected permanently'
						);
						const result = await context.outbound.sendToDeviceIds(game.controllerDeviceIds, msg, postToConnection);
						if (ResponseCode.Success !== result.responseCode) {
							Log.warn(`Failed to send game (permanent) disconnect message to controllers due to ${result}`);
						}
					}
					// Remove data
					// Remove device but tell it not to try to check for being in a game (since we know it's the Game's device)
					await context.backingStore.deleteDevice(device.id, false);
					await context.backingStore.deleteGame(game);
				}
			);

			Log.debug(`[${this.msgId}] Permanently disconnected ConnectionId:${connectionId}`);
			return MsgDisconnectDevicePermanentlyResponse.buildSuccess(this.msgId);
		} catch (e: unknown) {
			let errorString: string;
			switch (typeof e) {
				case 'string':
					errorString = e;
					break;

				default:
					errorString = JSON.stringify(e);
					break;
			}
			return MsgDisconnectDevicePermanentlyResponse.buildError(this.msgId, ResponseCode.Failure, errorString); // TODO: Leaks secret data!
		}
	};

	public buildSuccessResponse(): MsgDisconnectDevicePermanentlyResponse {
		return MsgDisconnectDevicePermanentlyResponse.buildSuccess(this.msgId);
	}

	public buildErrorResponse(responseCode: ResponseCode, errorDetails: string): MsgDisconnectDevicePermanentlyResponse {
		return MsgDisconnectDevicePermanentlyResponse.buildError(this.msgId, responseCode, errorDetails);
	}

	toString = (): string => `${this.constructor.name}(msgId:${this.msgId}, command:${this.command})`;
}

const Log = LogFactory.build(MsgDisconnectDevicePermanently.name);
