import { ResponseCode } from './ResponseCode';
import { DeviceId, NoDeviceId } from './DeviceId';
import MsgResponseBase from './MsgResponseBase';

export default class MsgGetDeviceIdResponse extends MsgResponseBase {
	/**
	 * The assigned device id.
	 *
	 * @TJS-type string
	 */
	public readonly deviceId: DeviceId;

	public static buildSuccess(msgId: number, deviceId: DeviceId) {
		return new MsgGetDeviceIdResponse(msgId, ResponseCode.Success, deviceId);
	}

	public static buildError(msgId: number, responseCode: ResponseCode, errorDetails: string) {
		return new MsgGetDeviceIdResponse(msgId, responseCode, NoDeviceId, errorDetails);
	}

	private constructor(msgId: number, responseCode: ResponseCode, deviceId: DeviceId, errorDetails?: string) {
		super(msgId, responseCode, errorDetails);
		this.deviceId = deviceId;
	}

	protected toStringExtra = (): string => {
		return `deviceId:${this.deviceId}`;
	};
}
