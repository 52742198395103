export enum WebSocketCloseCode {
	// See https://tools.ietf.org/html/rfc6455#section-7.4

	Normal = 1000,
	GoingAway = 1001,
	ProtocolError = 1002,

	/** Custom error code for retry */
	ClientConnectionTimeoutRetrying = 3003,
}
