import process from 'process';

/**
 * From an environmental variable named `envVarName` that has format similar to:
 * "MyClass=Silly,OtherClass=Log,Default=Warn"
 * extract the value that corresponds to `key`.
 * @param envVarName The environmental variable to use as the source of values.
 * @param key The key whose value to return.
 */
export function extractValueFromEnvVarNamed(envVarName: string, key: string): string | undefined {
	if (envVarName && process && process.env) {
		const envValue = process.env[envVarName];
		if (envValue) return extractValueFromVar(envValue, key);
	}

	return undefined;
}

/**
 * From a supplied variable that has format similar to:
 * "MyClass=Silly,OtherClass=Log,Default=Warn"
 * extract the value that corresponds to `key`.
 * @param variable The variable to use as the source of values.
 * @param key The key whose value to return.
 * @param separator Separates values (',' by default).
 */
export function extractValueFromVar(variable: string, key: string, separator: string = ','): string | undefined {
	const pairs = variable.split(separator);

	for (const pair of pairs) {
		const [pairKey, pairValue] = pair.split('=');

		if (pairKey.trim() === key) {
			return pairValue.trim();
		}
	}

	return undefined;
}
