export class ColourHelperTerminal {
	public static getColourCode(colourFg: Colour, colourBg: Colour = Colour.Unspecified): string {
		return Colour.Unspecified == colourBg ? `${Escape}[${colourFg}m` : `${Escape}[${colourFg};${colourBg + 10}m`;
	}

	public static getReset(): string {
		return Reset;
	}
}

const Escape = '\u001B';
const Reset = `${Escape}[m`;

export enum Colour {
	Unspecified = 0,
	Black = 30,
	Red = 31,
	Green = 32,
	Yellow = 33,
	Blue = 34,
	Magenta = 35,
	Cyan = 36,
	White = 37,
}
