import { IEquatable } from '../../src-ts/common/IEquatable';

export class TurnSet {
	public readonly numTurns: number;
	private readonly _turns: Turn[];

	public static fromJSON(json: string): TurnSet {
		const o = <ITurn[]>JSON.parse(json);
		const turns = new Array<Turn>(o.length);
		// const withMethods = new TurnSet(o.length);
		for (let i = 0; i < o.length; i++) {
			turns[i] = new Turn(o[i].action, o[i].data);
		}
		const withMethods = new TurnSet(turns);
		return withMethods;
	}

	constructor(numTurns: number);
	constructor(turns: Turn[]);
	constructor(turnsOrNumTurns: number | Turn[]) {
		if ('number' === typeof turnsOrNumTurns) {
			this.numTurns = turnsOrNumTurns;
			this._turns = Array<Turn>(this.numTurns);
			for (let i = 0; i < this.numTurns; i++) {
				this._turns[i] = new Turn('', '');
			}
		} else {
			this.numTurns = turnsOrNumTurns.length;
			this._turns = turnsOrNumTurns;
		}
	}

	public get = (index: number): Turn => {
		if (0 > index || index >= this._turns.length)
			throw new Error(`index:${index} is out of bounds of 0..${this._turns.length} ${this}`);

		return this._turns[index];
	};

	public set = (index: number, turn: Turn): void => {
		if (0 > index || index >= this._turns.length)
			throw new Error(`index:${index} is out of bounds of 0..${this._turns.length} ${this}`);

		this._turns[index] = turn;
	};

	public clear = (): void => {
		for (let i = 0; i < this._turns.length; i++) {
			this._turns[i].clear();
		}
	};

	public toJSON = (): string => JSON.stringify(this._turns);

	public toString = (): string => {
		let s = `${TurnSet.name}:[\n`;
		for (let i = 0; i < this._turns.length; i++) {
			s += `  ${i}\t: ${this._turns[i]}\n`;
		}
		s += '  ]';
		return s;
	};

	public equals(other: TurnSet): boolean {
		if (this.numTurns !== other.numTurns) return false;

		for (let i = 0; i < this.numTurns; i++) {
			if (!this._turns[i].equals(other._turns[i])) return false;
		}

		return true;
	}

	public buildSelectionWasMadeForTurns(): boolean[] {
		return this._turns.map((t) => '' !== t.action);
	}
}

interface ITurn {
	action: string;
	data: string;
}

export class Turn implements IEquatable<Turn>, ITurn {
	public action: string;
	public data: string;

	public static fromJSON(json: string): Turn {
		const o = <ITurn>JSON.parse(json);
		const withMethods = new Turn(o.action, o.data);
		return withMethods;
	}

	constructor(action: string, data: string) {
		this.action = action;
		this.data = data;
	}

	public clear = (): void => {
		this.action = '';
		this.data = '';
	};

	public equals(other: Turn): boolean {
		return this.action === other.action && this.data === other.data;
	}

	toString = (): string => `(action:\"${this.action}\", data:\"${this.data}\")`;
}
