import { JoinCode, joinCodeIsValid, JoinCodeValidText } from '../../src-ts/common/messages/JoinCode';
import BadWordsFilter from 'bad-words';
import {
	PlayerNameMaxLength,
	PlayerNameMinLength,
	PlayerNameRegexString,
} from '../../src-ts/common/utils/PlayerNameUtils';
import { WriterInputMinLength, WriterInputMaxLength } from './CharacterWritingData';

export function validateJoinCodeAndPlayerName(data: { joinCode: JoinCode; playerName: string }): {
	isValid: boolean;
	errorText?: string;
} {
	let result = validateJoinCode(data.joinCode);
	if (!result.isValid) return result;

	return validatePlayerName(data.playerName);
}

export function validateWriterInputText(inputText: string): {
	isValid: boolean;
	errorText?: string;
} {
	// Disallow empty/single-letter messages
	if (WriterInputMinLength > inputText.length) {
		return {
			isValid: false,
			errorText: `Your message is too short. It should be at least ${WriterInputMinLength} characters.`,
		};
	}
	// Disallow messages over max allowed length
	// Note: maxlength is also set on the HTML text field, so this shouldn't be seen, but here just in case.
	// The maxlength value of the TextArea (id: 'writerInputField') should match WriterInputMaxLength.
	if (WriterInputMaxLength < inputText.length) {
		return {
			isValid: false,
			errorText: `Your message is too long. It should be no more than ${WriterInputMaxLength} characters.`,
		};
	}
	// No bad words
	const badWords = new BadWordsFilter();
	if (badWords.isProfane(inputText)) {
		return { isValid: false, errorText: 'Sorry, that text failed our family friendliness check.' };
	}
	return { isValid: true };
}

function validateJoinCode(joinCode: JoinCode): { isValid: boolean; errorText?: string } {
	if (joinCodeIsValid(joinCode)) {
		return { isValid: true };
	}

	return {
		isValid: false,
		errorText: `Sorry, that join code is not quite right.  It should be ${JoinCodeValidText}.  Please check it?`,
	};
}

/**
 * Validate player name for length, with PlayerNameRegexString and naughty words (we're family friendly).
 * These are also done on the server in MessageValidator but it's better and faster to check on the client.
 */
function validatePlayerName(playerNameText: string): { isValid: boolean; errorText?: string } {
	if (PlayerNameMinLength > playerNameText.length) {
		return { isValid: false, errorText: 'Please enter a player name (at least 2 letters).' };
	}

	if (PlayerNameMaxLength < playerNameText.length) {
		return { isValid: false, errorText: 'Sorry, player names must be fewer than 32 letters.' };
	}

	if (!playerNameText.match(PlayerNameRegexString)) {
		return { isValid: false, errorText: 'Sorry, that player name is not allowed.' };
	}

	const badWords = new BadWordsFilter();
	if (badWords.isProfane(playerNameText)) {
		return { isValid: false, errorText: 'Sorry, that player name failed our family friendliness check.' };
	}

	return { isValid: true };
}
