import { Command } from './Command';

export default interface IMessage {
	readonly command: Command;
	readonly msgId: number;
}

export function isIMessage(o: any): o is IMessage {
	return undefined !== o.msgId && undefined !== o.command;
}
