import Log from '../../src-ts/common/Log';
import assert = require('assert');

export class HungKludge {
	/** The amount of time to wait before the game is considered hung. */
	private static readonly HangTime = 40_000;

	/** Id to find in the HTML to show the hint. */
	private static readonly HintId = 'reloadIfHung';

	private timer?: ReturnType<typeof setTimeout>;

	private showing: boolean = false;

	public startTimer = (): void => {
		Log.log('Starting hung timer (after hiding the hint)');
		this.hide();
		this.timer = setTimeout(() => {
			this.timer = undefined;
			this.show();
		}, HungKludge.HangTime);
	};

	public stopTimerAndHide = (): void => {
		if (this.timer) {
			Log.log('Cancelling reconnect timer');
			clearTimeout(this.timer);
			this.timer = undefined;
			this.hide();
		}
	};

	private show = (): void => {
		if (!this.showing) {
			this.showing = true;
			Log.debug('showing');
			// TODO: Show reload hint
			const hint = document.getElementById(HungKludge.HintId);
			assert(hint);
			hint.style.display = 'block';
		} else {
			Log.debug('already showing');
		}
	};

	private hide = (): void => {
		if (this.showing) {
			this.showing = false;
			Log.debug('hiding');
			// TODO: Hide reload hint
			const hint = document.getElementById(HungKludge.HintId);
			assert(hint);
			hint.style.display = 'none';
		} else {
			Log.debug('already hidden');
		}
	};
}
