import { ISimpleEvent, SimpleEventDispatcher } from 'strongly-typed-events';
import Log from '../../src-ts/common/Log';

export class ImageURLCache {
	private readonly keyToUrl = new Map<string, string>();

	public get onImageUrlCreated(): ISimpleEvent<string> {
		return this._onImageUrlCreated.asEvent();
	}
	private readonly _onImageUrlCreated = new SimpleEventDispatcher<string>();

	public constructor() {}

	public createImageObjectURL = async (imageName: string, imageBase64: string): Promise<void> => {
		const key: string = imageName;

		const cached: { success: boolean; url?: string } = this.tryGet(key);
		if (cached.success) {
			Log.log(`Already have cached URL for image '${key}': ${cached.url}`);
			return;
		}

		const base64Response = await fetch(`data:image/png;base64,${imageBase64}`);
		const blob = await base64Response.blob();

		if (this.tryGet(key).success) {
			// Image URL was added to cache while we were awaiting
			Log.warn(
				`Skipping creating URL for key ${key}, one was already created & cached while fetch/blob was taking place`
			);
			return;
		}

		const newUrl = URL.createObjectURL(blob);
		this.add(key, newUrl);

		this._onImageUrlCreated.dispatch(key);
	};

	public tryGet = (key: string): { success: boolean; url?: string } => {
		if (this.keyToUrl.has(key)) {
			return { success: true, url: this.keyToUrl.get(key) };
		}
		return { success: false };
	};

	private add = (key: string, urlToAdd: string): void => {
		if (this.keyToUrl.has(key)) {
			throw new Error(`URL image cache already contains an entry with key '${key}'`);
		}
		this.keyToUrl.set(key, urlToAdd);
		Log.log(`Added image URL to cache - key: '${key}', url: ${urlToAdd}`);
	};
}
