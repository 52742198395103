import Log from '../../src-ts/common/Log';
import { ImageURLCache } from './ImageURLCache';

/**
 * Defines the look of an action button (label, image, colour etc.),
 * as well as the action that will be set when the button is pressed.
 */
export class ControllerActionButton {
	public readonly action: string;
	public enabled?: boolean;
	public readonly hasDirectionData?: boolean;
	public readonly label?: string;
	public readonly imageName?: string;
	public readonly imageBase64?: string;
	public readonly mainColour?: string;
	public readonly secondaryColour?: string;

	public get enabledOrDefault(): boolean {
		return this.enabled ?? true;
	}
	public get hasDirectionDataOrDefault(): boolean {
		return this.hasDirectionData ?? false;
	}
	public get labelOrDefault(): string {
		return this.label ?? '';
	}
	public get mainColourOrDefault(): string {
		return this.mainColour ?? '#000000';
	}
	public get secondaryColourOrDefault(): string {
		return this.secondaryColour ?? this.mainColour ?? '#000000';
	}

	private _isCoOperationButton: boolean;
	public get isCoOperationButton(): boolean {
		return this._isCoOperationButton;
	}

	public constructor(
		isCoOperationButton: boolean,
		action: string,
		enabled: boolean | undefined,
		hasDirectionData: boolean | undefined,
		label: string | undefined,
		imageName: string | undefined,
		imageBase64: string | undefined,
		mainColour: string | undefined,
		secondaryColour: string | undefined
	) {
		this._isCoOperationButton = isCoOperationButton;
		this.action = action;
		this.enabled = enabled;
		this.hasDirectionData = hasDirectionData;
		this.label = label;
		this.imageName = imageName;
		this.imageBase64 = imageBase64;
		this.mainColour = mainColour;
		this.secondaryColour = secondaryColour;
	}

	public toString = (): string => {
		return `enabled: ${this.enabled}, action: ${this.action}, hasDirectionData: ${this.hasDirectionData}, label: ${
			this.label
		}, image: ${this.getTruncatedImageText()}, mainColour: ${this.mainColour}, secondaryColour: ${
			this.secondaryColour
		}`;
	};

	public createImageObjectURL = async (imageURLCache: ImageURLCache): Promise<void> => {
		if (!this.imageBase64) {
			return;
		}

		if (!this.imageName) {
			throw new Error(`Expected imageName to have a value when creating image URL for ${this}`);
		}

		await imageURLCache.createImageObjectURL(this.imageName, this.imageBase64);
	};

	private getTruncatedImageText = (): string => {
		const Length: number = 10;
		if (!this.imageBase64) {
			return '';
		}
		if (this.imageBase64.length <= Length) {
			return this.imageBase64;
		}
		return `${this.imageBase64.substring(0, Length - 1)}...`;
	};
}
