import Log from '../../src-ts/common/Log';

export const WriterInputMinLength = 2;
export const WriterInputMaxLength = 100;

export class CharacterWritingData {
	public readonly characterNamesAndText: CharacterNameAndText[];

	public get selectedCharacterInternalName(): string {
		return this.characterNamesAndText[this.selectedCharacterIndex].internalName;
	}
	public get selectedCharacterDisplayName(): string {
		return this.characterNamesAndText[this.selectedCharacterIndex].displayName;
	}
	public get selectedCharacterText(): string {
		return this.characterNamesAndText[this.selectedCharacterIndex].text;
	}

	private _selectedCharacterIndex: number;
	public get selectedCharacterIndex(): number {
		return this._selectedCharacterIndex;
	}

	public get isFirstCharacterSelected(): boolean {
		return this._selectedCharacterIndex === 0;
	}
	public get isLastCharacterSelected(): boolean {
		return this._selectedCharacterIndex === this.characterNamesAndText.length - 1;
	}

	public constructor(
		characterInternalNameData: string[],
		characterDisplayNameData: string[],
		restoreText: Map<string, string>
	) {
		let playerCharacterNames: CharacterNameAndText[] = [];
		let otherCharacterNames: CharacterNameAndText[] = [];
		characterInternalNameData.forEach((name, index) => {
			const characterNameAndText = new CharacterNameAndText(name, characterDisplayNameData[index]);
			if (restoreText.has(name)) {
				characterNameAndText.setText(restoreText.get(name)!);
			}
			if (name.includes('player_')) {
				playerCharacterNames.push(characterNameAndText);
			} else {
				otherCharacterNames.push(characterNameAndText);
			}
		});

		playerCharacterNames.sort((a, b) => a.displayName.localeCompare(b.displayName));
		otherCharacterNames.sort((a, b) => a.displayName.localeCompare(b.displayName));

		this.characterNamesAndText = playerCharacterNames.concat(otherCharacterNames);

		this._selectedCharacterIndex = 0;
	}

	public setTextInputForSelectedCharacter(text: string): void {
		this.characterNamesAndText[this.selectedCharacterIndex].setText(text);
	}

	public setSelectedCharacterIndex = (newIndex: number): void => {
		if (newIndex < 0 || newIndex >= this.characterNamesAndText.length) {
			Log.warn(
				`setSelectedCharacterIndex with invalid index: ${newIndex} (Expected 0-${
					this.characterNamesAndText.length - 1
				})`
			);
			return;
		}
		this._selectedCharacterIndex = newIndex;
	};
	public incrementSelectedCharacterIndex = (): void => {
		if (this.selectedCharacterIndex >= this.characterNamesAndText.length - 1) {
			return;
		}
		this._selectedCharacterIndex++;
	};
	public decrementSelectedCharacterIndex = (): void => {
		if (this.selectedCharacterIndex <= 0) {
			return;
		}
		this._selectedCharacterIndex--;
	};
	public resetSelectedCharacterIndex = (): void => {
		this._selectedCharacterIndex = 0;
	};

	public getCharacterNameToTextMap = (): Map<string, string> => {
		let map = new Map<string, string>();
		this.characterNamesAndText.forEach((nameAndText) => {
			if (nameAndText.text !== '') {
				map.set(nameAndText.internalName, nameAndText.text);
			}
		});
		return map;
	};
}

export class CharacterNameAndText {
	public readonly internalName: string;
	public readonly displayName: string;

	private _text: string;
	public get text(): string {
		return this._text;
	}

	public constructor(internalName: string, displayName: string) {
		this.internalName = internalName;
		this.displayName = displayName;
		this._text = '';
	}

	public setText = (newText: string): void => {
		// Ensure text is within max length limit
		if (newText.length > WriterInputMaxLength) {
			newText = newText.substring(0, WriterInputMaxLength);
		}
		// Ensure no line breaks
		newText = newText.replace(/(\r\n|\n|\r)/gm, '');
		this._text = newText;
	};
}
