import { ResponseCode } from './ResponseCode';
import MsgResponseBase from './MsgResponseBase';

export default class MsgAddControllerResponse extends MsgResponseBase {
	public static buildSuccess(msgId: number) {
		return new MsgAddControllerResponse(msgId, ResponseCode.Success);
	}

	public static buildError(msgId: number, responseCode: ResponseCode, errorDetails: string) {
		return new MsgAddControllerResponse(msgId, responseCode, errorDetails);
	}

	private constructor(msgId: number, responseCode: ResponseCode, errorDetails?: string) {
		super(msgId, responseCode, errorDetails);
	}
}
