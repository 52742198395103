import Log from '../Log';
import MsgBase from './MsgBase';
import IMessage, { isIMessage } from './IMessage';
import { Command, CommandValue } from './Command';
import { ServerInfoCode, ServerInfoCodeToName } from './ServerInfoCode';
import { toString } from '../../server/utils/JsonUtils';

export default class MsgInfoFromServer extends MsgBase {
	public readonly command: Command = CommandValue.ServerInfo;

	public readonly infoCode: ServerInfoCode;

	public readonly details: string;

	/**
	 * An informational message from the server.
	 *
	 * @param infoCode Code indicating the information conveyed.
	 * @param details Textual explanation on the situation.
	 * @param msgId Optional message Id.
	 *   Automatically generated for new messages and used to correlate with responses.
	 *   Should be unique from a given client.  Globally unique when combined with the device Id.
	 */
	public constructor(infoCode: ServerInfoCode, details: string, msgId: number = MsgBase.MsgIdAuto) {
		// 2023/01/23 This used to use MessageIdFactoryServer to generate numbers but since we now have to await a database operation,
		// we instead store the auto value then set it to the actual value when it is sent! (See `Outbound`.)
		super(MsgBase.processSuppliedMsgIdInReservedRangeIntoValue(msgId));
		this.infoCode = infoCode;
		this.details = details;
	}

	public static isMsgInfoFromServer(o: any): o is MsgInfoFromServer {
		return isIMessage(o) && CommandValue.ServerInfo === o.command;
	}

	public static buildFromIMessage(o: IMessage): MsgInfoFromServer {
		if (!MsgInfoFromServer.isMsgInfoFromServer(o)) {
			const errMsg = `Non-${MsgInfoFromServer.name} input supplied: ${toString(o)}`;
			Log.error(errMsg);
			throw new Error(errMsg);
		}
		const withMethods = new MsgInfoFromServer(
			ServerInfoCode.UnknownDoNotUse,
			'InvalidDataToBeOverwritten',
			this.MsgIdInvalidToBeOverwritten
		);
		Object.assign(withMethods, o); // copy `o` over withMethods
		return withMethods;
	}

	toString = (): string =>
		`${this.constructor.name}(msgId:${this.msgId}, command:${this.command}, infoCode:${ServerInfoCodeToName(
			this.infoCode
		)}(${this.infoCode}), details:"${this.details}")`;
}
