import { App } from './App';
import { ControllerState } from './ControllerState';

/**
 * Keyboard shortcuts:
 * - 1-4 for action type selection (1 is move, 2 is act etc),
 * - WASD for directions (w = north etc),
 * - 'r' for 'Ready'.
 */
export default class KeyboardShortcuts {
	private isActive = false;

	private readonly controls: IControl[] = [
		new Control('1', async () => this.app.onSelectActionButtonPressed(null, 0)),
		new Control('2', async () => this.app.onSelectActionButtonPressed(null, 1)),
		new Control('3', async () => this.app.onSelectActionButtonPressed(null, 2)),
		new Control('4', async () => this.app.onSelectActionButtonPressed(null, 3)),

		new Control('w', async () => await this.app.onSelectDataButtonPressed(null, 'north')),
		new Control('d', async () => await this.app.onSelectDataButtonPressed(null, 'east')),
		new Control('s', async () => await this.app.onSelectDataButtonPressed(null, 'south')),
		new Control('a', async () => await this.app.onSelectDataButtonPressed(null, 'west')),

		new Control('r', async () => await this.app.onReadyButtonPressed(null)),

		// JA Updated 2023-01-31 to make more sense with new controls, feel free to make further changes
		// Note: Removed enter key triggering 'ready' because this interferes with tab/enter key navigation
	];

	constructor(private app: App) {
		document.addEventListener('keypress', this.onKeyPress);
	}

	private onKeyPress = async (event: KeyboardEvent) => {
		if (!this.isActive) return;

		if (this.app.activeGameSessionInView.isNonCharacterPlayer) {
			// Active session is not controlling a character, ignore keyboard shortcuts
			return;
		}
		if (this.app.mostRecentControllerState !== ControllerState.WaitingForInput) {
			// Not waiting for player input, ignore keyboard shortcuts
			return;
		}

		const key = event.shiftKey ? event.key.toUpperCase() : event.key;
		for (const control of this.controls) {
			if (control.key === key) {
				await control.delegate();
				this.app.consumeEvent(event);
				return;
			}
		}
	};

	public setActive = (active: boolean): void => {
		this.isActive = active;
	};
}

interface IControl {
	key: string;
	delegate: () => void;
}

class Control implements IControl {
	public readonly key: string;
	public readonly delegate: () => void;

	constructor(key: string, delegate: () => void) {
		this.key = key;
		this.delegate = delegate;
	}
}
