import { JoinCode } from '../../src-ts/common/messages/JoinCode';
import { LogFactory } from '../../src-ts/common/Log';
import { DeviceId } from '../../src-ts/common/messages/DeviceId';
import { TurnSetWithSelections } from './TurnSetWithSelections';

export class CookieHelper {
	private static readonly CookieNameSessionsList = 'sessionsList';

	private static readonly CookieNameJoinCode = 'joinCode';

	private static readonly CookieNameDeviceIdPrefix = 'deviceId';

	private static readonly CookieNameTurnSetPrefix = 'turnSet';
	private static readonly CookieNameSelectedTurnPrefix = 'selectedTurn';

	private static readonly CookieNameCharacterTextPrefix = 'characterText';

	public setSessionsList = (sessionsList: [number, string][]): void => {
		if (0 === sessionsList.length) {
			Log.log('setSessionsList(): clearing');
			CookieHelper.eraseCookie(CookieHelper.CookieNameSessionsList);
			return;
		}

		const listJSON = JSON.stringify(sessionsList);
		Log.log('setSessionsList():', listJSON);
		CookieHelper.setCookie(CookieHelper.CookieNameSessionsList, listJSON, 1);
	};

	public getSessionsList = (): [number, string][] | undefined => {
		const resultJSON = CookieHelper.getCookie(CookieHelper.CookieNameSessionsList);
		Log.log('getSessionsList():', resultJSON);
		if (undefined === resultJSON) return undefined;

		try {
			const sessionList = JSON.parse(resultJSON);
			return sessionList;
		} catch {
			return undefined;
		}
	};

	public setJoinCode = (joinCode: JoinCode): void => {
		Log.log('setJoinCode():', joinCode);
		CookieHelper.setCookie(CookieHelper.CookieNameJoinCode, joinCode, 1);
	};

	public getJoinCode = (): string | undefined => {
		const result = CookieHelper.getCookie(CookieHelper.CookieNameJoinCode);
		Log.log('getJoinCode():', result);
		return result;
	};

	public clearJoinCode = (): void => {
		Log.log('clearJoinCode()');
		CookieHelper.eraseCookie(CookieHelper.CookieNameJoinCode);
	};

	public setCharacterTextForSessionIndex = (sessionIndex: number, characterText: Map<string, string>): void => {
		const characterTextJSON: string = JSON.stringify(Array.from(characterText.entries()));
		Log.debug(`setCharacterTextForSessionIndex(${sessionIndex}, ${characterText.size} ${characterTextJSON})`);
		CookieHelper.setCookie(`${CookieHelper.CookieNameCharacterTextPrefix}-${sessionIndex}`, characterTextJSON, 1);
	};

	public getCharacterTextForSessionIndex = (sessionIndex: number): Map<string, string> => {
		const result = CookieHelper.getCookie(`${CookieHelper.CookieNameCharacterTextPrefix}-${sessionIndex}`);
		Log.debug(`getCharacterTextForSessionIndex(${sessionIndex}): = ${result}`);
		if (result === undefined) {
			return new Map<string, string>();
		}
		const characterNamesAndTextMap = new Map<string, string>(JSON.parse(result));
		return characterNamesAndTextMap;
	};

	public clearCharacterTextForSessionIndex = (sessionIndex: number): void => {
		Log.log(`clearCharacterTextForSessionIndex(${sessionIndex})`);
		CookieHelper.eraseCookie(`${CookieHelper.CookieNameCharacterTextPrefix}-${sessionIndex}`);
	};

	public setDeviceIdForSessionIndex = (sessionIndex: number, deviceId: DeviceId): void => {
		Log.debug(`setDeviceIdForSessionIndex(${sessionIndex}, ${deviceId})`);
		CookieHelper.setCookie(`${CookieHelper.CookieNameDeviceIdPrefix}-${sessionIndex}`, deviceId, 1);
	};

	public getDeviceIdForSessionIndex = (sessionIndex: number): string | undefined => {
		const result = CookieHelper.getCookie(`${CookieHelper.CookieNameDeviceIdPrefix}-${sessionIndex}`);
		Log.debug(`getDeviceIdForSessionIndex(${sessionIndex}): = ${result}`);
		return result;
	};

	public clearDeviceIdForSessionIndex = (sessionIndex: number): void => {
		Log.log(`clearDeviceIdForSessionIndex(${sessionIndex})`);
		CookieHelper.eraseCookie(`${CookieHelper.CookieNameDeviceIdPrefix}-${sessionIndex}`);
	};

	public setTurnSetForSessionIndex = (sessionIndex: number, turnSet: TurnSetWithSelections): void => {
		const json = turnSet.toJSON();
		Log.debug(`setTurnSetForSessionIndex(${sessionIndex}): ${json}`);
		CookieHelper.setCookie(`${CookieHelper.CookieNameTurnSetPrefix}-${sessionIndex}`, json, 1);
	};

	public getTurnSetForSessionIndex = (sessionIndex: number): TurnSetWithSelections | undefined => {
		const turnsJSON = CookieHelper.getCookie(`${CookieHelper.CookieNameTurnSetPrefix}-${sessionIndex}`);
		Log.log(`getTurnSetForSessionIndex(${sessionIndex}): ${turnsJSON}`);
		if (undefined === turnsJSON) return undefined;
		return TurnSetWithSelections.fromJSON(turnsJSON);
	};

	public clearTurnSetForSessionIndex = (sessionIndex: number): void => {
		Log.log(`clearTurnSetForSessionIndex(${sessionIndex})`);
		CookieHelper.eraseCookie(`${CookieHelper.CookieNameTurnSetPrefix}-${sessionIndex}`);
	};

	public setSelectedTurnForSessionIndex = (sessionIndex: number, selectedTurnNumber: number): void => {
		Log.debug(`setSelectedTurnForSessionIndex(${sessionIndex}): ${selectedTurnNumber}`);
		CookieHelper.setCookie(
			`${CookieHelper.CookieNameSelectedTurnPrefix}-${sessionIndex}`,
			selectedTurnNumber.toString(),
			1
		);
	};

	public getSelectedTurnForSessionIndex = (sessionIndex: number): number => {
		const selectedTurn = CookieHelper.getCookie(`${CookieHelper.CookieNameSelectedTurnPrefix}-${sessionIndex}`);
		Log.log(`getSelectedTurnForSessionIndex(${sessionIndex}): ${selectedTurn}`);
		if (undefined == selectedTurn) return 0;
		return parseInt(selectedTurn);
	};

	public clearSelectedTurnForSessionIndex = (sessionIndex: number): void => {
		Log.log(`clearSelectedTurnForSessionIndex(${sessionIndex})`);
		CookieHelper.eraseCookie(`${CookieHelper.CookieNameSelectedTurnPrefix}-${sessionIndex}`);
	};

	// Cookie helper functions from https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript

	private static setCookie(name: string, value: string, days: number): void {
		let expires = '';
		if (days) {
			const date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		// Uses SameSite=Strict to prevent CSRF attacks and reduce console warnings.
		// See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie#samesitesamesite-value
		document.cookie = name + '=' + (value || '') + expires + '; SameSite=Strict; path=/';
	}

	private static getCookie(name: string): string | undefined {
		const nameEQ = name + '=';
		const ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (' ' === c.charAt(0)) c = c.substring(1, c.length);
			if (0 === c.indexOf(nameEQ)) return c.substring(nameEQ.length, c.length);
		}
		return undefined;
	}

	private static eraseCookie(name: string): void {
		document.cookie = name + '=; SameSite=Strict; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}
}

const Log = LogFactory.build(CookieHelper.name);
