import MsgBase from './MsgBase';
import { Command, CommandValue } from './Command';
import { ServerInfoCode } from './ServerInfoCode';
import MsgInfoFromServer from './MsgInfoFromServer';
import { DeviceId } from './DeviceId';
import IMessage, { isIMessage } from './IMessage';
import Log from '../Log';
import { toString } from '../../server/utils/JsonUtils';

export default class MsgInfoFromServerControllerConnected extends MsgInfoFromServer {
	public readonly command: Command = CommandValue.ServerInfoControllerConnected;

	/**
	 * Device that connected.
	 * @TJS-type string
	 */
	public readonly deviceId: DeviceId;

	/**
	 * Player name.
	 * Not present on reconnect.
	 * @TSJ-type string
	 */
	readonly playerName?: string;

	/**
	 * Whether this is a reconnecting device.
	 * @TSJ-type boolean
	 */
	readonly isReconnect: boolean;

	/**
	 * An informational message from the server indicating that a controller has connected.
	 *
	 * @param deviceId Which device connected.
	 * @param playerName The name of the player who connected.  Not present when reconnecting.
	 * @param isReconnect True when this message is reconnect.
	 * @param msgId Optional message id.
	 *   Automatically generated for new messages and used to correlate with responses.
	 *   Should be unique from a given client.  Globally unique when combined with the device Id.
	 */
	public constructor(
		deviceId: DeviceId,
		playerName?: string,
		isReconnect: boolean = false,
		msgId: number = MsgBase.MsgIdAuto
	) {
		super(ServerInfoCode.ControllerConnected, '', msgId);
		this.deviceId = deviceId;
		this.playerName = playerName;
		this.isReconnect = isReconnect;
	}

	public static isValid(o: any): o is MsgInfoFromServerControllerConnected {
		return isIMessage(o) && CommandValue.ServerInfoControllerConnected === o.command;
	}

	public static buildFromIMessage(o: IMessage): MsgInfoFromServerControllerConnected {
		if (!MsgInfoFromServerControllerConnected.isValid(o)) {
			const errMsg = `Non-${MsgInfoFromServerControllerConnected.name} input supplied: ${toString(o)}`;
			Log.error(errMsg);
			throw new Error(errMsg);
		}
		const withMethods = new MsgInfoFromServerControllerConnected(o.deviceId, o.playerName, o.isReconnect, o.msgId);
		// Object.assign(withMethods, o); // copy `o` over withMethods = redundant in this case
		return withMethods;
	}

	toString = (): string =>
		`${this.constructor.name}(msgId:${this.msgId}, command:${this.command}, infoCode:${this.infoCode}, deviceId:${this.deviceId} details:"${this.details}")`;
}
