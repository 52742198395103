import MsgBase from '../common/messages/MsgBase';

export class MessageIdFactoryClient {
	public static buildMsgId(msgId: number): number {
		// id increments (positively)
		// Handle number overflow and restarting sequence
		if (Number.MAX_SAFE_INTEGER === MessageIdFactoryClient.nextMsgId) {
			MessageIdFactoryClient.nextMsgId = MsgBase.MsgReservedMax + 1;
		}
		return MsgBase.MsgIdAuto !== msgId && MsgBase.MsgReservedMax < msgId ? msgId : MessageIdFactoryClient.nextMsgId++;
	}

	private static nextMsgId: number = MsgBase.MsgReservedMax + 1;
}
