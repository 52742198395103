import { ControllerActionButton } from './ControllerActionButton';
import Log from '../../src-ts/common/Log';
import { App } from './App';

/**
 * Data that defines parts of the controller that can be customised by modders.
 * For now, this is just the action buttons.
 */
export class CustomControllerData {
	public actionButtons?: ControllerActionButton[];

	public constructor(actionButtons?: ControllerActionButton[]) {
		this.actionButtons = actionButtons;
	}

	public setActionButton = (index: number, actionButton: ControllerActionButton) => {
		if (index < 0 || index >= App.ActionButtonCount) {
			throw new Error(
				`Invalid index (${index}, expected between 0 and ${
					App.ActionButtonCount - 1
				}) when setting action button on ${this}`
			);
		}

		if (!this.actionButtons) {
			this.actionButtons = [];
		}
		this.actionButtons[index] = actionButton;

		Log.log(`Set actionButton at index ${index} to: ${actionButton}`);
	};

	public toString = (): string => {
		return `${CustomControllerData.name} (${this.actionButtons ? this.actionButtons.join(' | ') : 'Empty'})`;
	};

	// Default (CO OPERATION) data defining actions & related action button properties
	public static getDefaultControllerData = (): CustomControllerData => {
		return new CustomControllerData([
			new ControllerActionButton(true, 'move', true, true, 'Move', '', undefined, '#019748', '#bdffdc'),
			new ControllerActionButton(true, 'act', true, true, 'Interact', '', undefined, '#c86400', '#ffd9b3'),
			new ControllerActionButton(true, '', true, false, 'Wait', undefined, '', '#2397e4', '#b9e3ff'),
			new ControllerActionButton(true, 'throw', true, true, 'Throw', undefined, '', '#e8d734', '#fffdc0'),
		]);
	};

	public static isDefaultActionName = (actionName: string): boolean => {
		const actionWithName = CustomControllerData.getDefaultControllerData().actionButtons!.find(
			(a) => a.action === actionName
		);
		return actionWithName !== undefined;
	};
}
