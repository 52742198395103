import MsgBase from './MsgBase';
import { Command, CommandValue } from './Command';
import { Data } from './Data';
import IMessage, { isIMessage } from './IMessage';
import MsgResponseBase from './MsgResponseBase';
import { DeviceId, NoDeviceId } from './DeviceId';
import MsgMessageResponse from './MsgMessageResponse';
import { ResponseCode } from './ResponseCode';
import { LogFactory } from '../Log';
import MsgClientSentBase from './MsgClientSentBase';
import { ConnectionId } from './ConnectionId';
import { PostToConnection } from 'aws-lambda-ws-server';
import { JoinCode, NoJoinCode } from './JoinCode';
import { IMessageRunContext } from '../../server/IRoutingServer';
import { DefaultSerializer } from '../utils/Serializer';

export interface IMsgMessage extends IMessage {
	/**
	 * Device Id of the sender.
	 * (Filled-in by the server in WebSocket implementation.)
	 * @minimum 0
	 * @TJS-type integer
	 */
	deviceId?: DeviceId;
	// N.b. DeviceId is now a string so the schema spec. here is irrelevant.

	/**
	 * Code to join the game.
	 *
	 * @minimum 0
	 * @maximum 9999
	 * @TJS-type integer
	 */
	readonly joinCode: JoinCode;

	/**
	 * Data to send to the device(s).
	 */
	readonly data: Data;
}

export default class MsgMessage extends MsgClientSentBase<MsgMessageResponse> implements IMsgMessage {
	/**
	 * Messages received out-of-order are ignored based upon the value associated with this field.
	 * E.g. `state` messages are compared to one another and not with `narrative` messages (which are only compared with other `narrative` messages).
	 * IMPORTANT: Ensure this is kept in-sync with the Game code:
	 * `cooperation.multiturn.messages.connection.Constants.MsgMessage.MsgTopicName`.
	 */
	public static MsgTopicName = 'meta.message.topic';

	public readonly command: Command = CommandValue.Message;

	/**
	 * Device Id of the sender.
	 * (Filled-in by the server in WebSocket implementation.)
	 * @TJS-type string
	 */
	public deviceId?: DeviceId;
	// N.b. DeviceId is now a string so the schema spec. here is irrelevant.

	/**
	 * Code to join the game.
	 *
	 * @minimum 0
	 * @maximum 9999
	 * @TJS-type integer
	 */
	readonly joinCode: JoinCode;

	/**
	 * Data to send to the device(s).
	 */
	public readonly data: Data;

	/**
	 * Lazily parsed data object.
	 * @private
	 */
	private _dataObjCached?: any; // Omitted from JSON serialization by DefaultSerializer (used by Connection.sendToServer()).
	// TODO-2021/09/26: Better type for `_dataObjCached`?

	/**
	 *
	 * @param data Data to be sent to the recipient(s).
	 * @param joinCode The join code of the game.
	 * @param deviceId Optionally client-supplied sender device Id.
	 *   Valid (as yet) to be -1 (NoDeviceId) and it will be populated by the sender (WebSockets) or inferred by the receiver (P2P).
	 * @param msgId Optional message Id.
	 *   Automatically generated for new messages and used to correlate with responses.
	 *   Should be unique from a given client.  Globally unique when combined with the device Id.
	 */
	public constructor(
		data: Data,
		joinCode: JoinCode,
		deviceId: DeviceId = NoDeviceId,
		msgId: number = MsgBase.MsgIdAuto
	) {
		super(msgId);
		this.data = data;
		this.joinCode = joinCode;
		this.deviceId = deviceId;
		if ('string' !== typeof (<any>data)) {
			const errMsg = `Non-string 'data' supplied in ${this}`; // TODO: Better error
			Log.error(errMsg);
			throw new Error(errMsg);
		}
	}

	public static isMsgMessage(o: any): o is MsgMessage {
		// deviceId is optional so omitted below
		return undefined !== o.data && 'string' === typeof o.data && isIMessage(o) && CommandValue.Message === o.command;
	}

	public static buildFromIMessage(o: IMessage): MsgMessage {
		if (!MsgMessage.isMsgMessage(o)) {
			const errMsg = `Non-${MsgMessage.name} input supplied: ${JSON.stringify(o)}`;
			Log.error(errMsg);
			throw new Error(errMsg);
		}
		const withMethods = new MsgMessage(
			'InvalidDataToBeOverwritten',
			NoJoinCode,
			NoDeviceId,
			this.MsgIdInvalidToBeOverwritten
		);
		Object.assign(withMethods, o); // copy `o` over withMethods
		return withMethods;
	}

	/**
	 * On the server, this is called by `RoutingServer.processMessage()` to perform the message.
	 */
	public run = async (
		connectionId: ConnectionId,
		context: IMessageRunContext,
		postToConnection: PostToConnection
	): Promise<MsgResponseBase> => {
		if (NoDeviceId === this.deviceId) {
			try {
				const device = (await context.backingStore.getDeviceByConnectionId(connectionId))!; // throws if undefined
				this.deviceId = device.id;
			} catch (e) {
				// Error if unknown.  (impossible atm since uses ConnectionId for deviceId but future reconnect code ...)
				return MsgMessageResponse.buildError(
					this.msgId,
					ResponseCode.Failure,
					`No known deviceId for this connection for ${this}`
				);
			}
		}

		const game = await context.backingStore.getGameByJoinCode(this.joinCode);
		if (!game) {
			return MsgMessageResponse.buildError(
				this.msgId,
				ResponseCode.Failure,
				`Failed to find game "${this.joinCode}" for ${this}`
			);
		}

		// Do the actual send of the message.
		return context.outbound.sendToOthersForSenderDeviceId(game, this.deviceId!, this, postToConnection);
	};

	public buildErrorResponse(responseCode: ResponseCode, errorDetails: string): MsgMessageResponse {
		return MsgMessageResponse.buildError(this.msgId, responseCode, errorDetails);
	}

	/**
	 * Get the data as decoded into an object.
	 * Caches the result to allow multiple uses without re-parsing or passing around.
	 * Uses `DefaultSerializer` to prepare for different deserialization methods in the future (e.g. MsgPack).
	 */
	public getDataDecoded = (): any | undefined => {
		if (this._dataObjCached) return this._dataObjCached;

		try {
			return (this._dataObjCached = DefaultSerializer.deserialize(this.data));
		} catch (e: unknown) {
			//infoView.error('Failed to parse message from Game due to:', e, 'message was:', this);
			return undefined;
		}
	};

	toString = (): string =>
		`${this.constructor.name}(msgId:${this.msgId}, command:${this.command}, joinCode:${this.joinCode}, deviceId?:${this.deviceId}, data:${this.data})`;
}

const Log = LogFactory.build(MsgMessage.name);
