import { ResponseCode } from './ResponseCode';
import MsgResponseBase from './MsgResponseBase';

export default class MsgMessageResponse extends MsgResponseBase {
	/**
	 * The number of recipients successfully delivered to.
	 * This may be fewer than the number of intended recipients if some of them are offline.
	 * E.g. Game sending to 4 controllers but 1 is temporarily disconnected.
	 */
	public readonly numRecipientsDeliveredTo: number;

	public static buildSuccess(msgId: number, numRecipientsDeliveredTo: number) {
		return new MsgMessageResponse(msgId, ResponseCode.Success, numRecipientsDeliveredTo);
	}

	public static buildError(msgId: number, responseCode: ResponseCode, errorDetails: string) {
		return new MsgMessageResponse(msgId, responseCode, 0, errorDetails);
	}

	private constructor(
		msgId: number,
		responseCode: ResponseCode,
		numRecipientsDeliveredTo: number,
		errorDetails?: string
	) {
		super(msgId, responseCode, errorDetails);
		// Invalid to use negative recipients or success with zero
		if (0 > numRecipientsDeliveredTo || (ResponseCode.Success === responseCode && 0 === numRecipientsDeliveredTo)) {
			const errMsg = `numRecipientsDeliveredTo:${numRecipientsDeliveredTo} and responseCode:${responseCode}`;
			throw new Error(errMsg);
		}
		this.numRecipientsDeliveredTo = numRecipientsDeliveredTo;
	}

	override toStringExtra = (): string => `numDeliveredTo:${this.numRecipientsDeliveredTo}`;
}
