import MsgResponseBase from './MsgResponseBase';
import MsgBase from './MsgBase';
import { ResponseCode } from './ResponseCode';
import { ConnectionId } from './ConnectionId';
import { PostToConnection } from 'aws-lambda-ws-server';
import { IMessageRunContext } from '../../server/IRoutingServer';
import { MessageIdFactoryClient } from '../../client/MessageIdFactoryClient';

export abstract class MsgClientSentBaseBase extends MsgBase {
	/**
	 * On the server, this is called by `RoutingServer.processMessage()` to do perform the message.
	 */
	abstract run(
		connectionId: ConnectionId,
		context: IMessageRunContext,
		postToConnection: PostToConnection
	): Promise<MsgResponseBase>;

	public abstract buildErrorResponse(responseCode: ResponseCode, errorDetails: string): MsgResponseBase;
}

export default abstract class MsgClientSentBase<T extends MsgResponseBase> extends MsgClientSentBaseBase {
	protected constructor(msgId: number = MsgBase.MsgIdAuto) {
		super(MessageIdFactoryClient.buildMsgId(MsgBase.processSuppliedMsgIdInReservedRangeIntoValue(msgId)));
	}

	public abstract buildErrorResponse(responseCode: ResponseCode, errorDetails: string): T;

	toString = (): string => `${this.constructor.name}(msgId:${this.msgId}, command:${this.command})`;
}
