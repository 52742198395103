import IResponse from './IResponse';
import { ResponseCode, ResponseCodeToName } from './ResponseCode';

export default abstract class MsgResponseBase implements IResponse {
	/**
	 * Matches the message being responded to.
	 * Correlating to the sent message allows determining the type of the message.
	 *
	 * @minimum 0
	 * @TJS-type integer
	 */
	public readonly msgId: number;

	public readonly responseCode: ResponseCode;

	/**
	 * Only supplied if some error is indicated by `responseCode`.
	 */
	public readonly errorDetails?: string;

	protected constructor(msgId: number, responseCode: ResponseCode, errorDetails?: string) {
		this.msgId = msgId;
		this.responseCode = responseCode;
		this.errorDetails = errorDetails;
	}

	toString = (): string => {
		if (this.errorDetails) {
			return `${this.constructor.name}(msgId:${this.msgId}, responseCode:${ResponseCodeToName(this.responseCode)}(${
				this.responseCode
			}), ${this.toStringExtra()} "${this.errorDetails}")`;
		} else {
			return `${this.constructor.name}(msgId:${this.msgId}, responseCode:${ResponseCodeToName(this.responseCode)}(${
				this.responseCode
			}) ${this.toStringExtra()})`;
		}
	};

	protected toStringExtra(): string {
		return '';
	}
}
