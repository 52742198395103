export type JoinCode = string;
// N.b. changing JoinCode type requires changing schema for validation including where it's used in Messages
// (since schema is sometimes generated from these via the JDoc).

export const NoJoinCode = '';

export const MinimumJoinCode = '0000';

export const JoinCodeValidText = '1-4 numbers';

export function joinCodeIsValid(joinCode: JoinCode): boolean {
	// non-null and 4-digits only
	return !!joinCode && /^\d{4}$/.test(joinCode);
}
