import { LogFactory } from '../../../common/Log';
import DeviceRecord from '../DeviceRecord';
import IBackingStore from '../IBackingStore';
import { DeviceId } from '../../../common/messages/DeviceId';
import { Game } from '../Game';
import { JoinCode } from '../../../common/messages/JoinCode';
import { ServerMessageIdRecord } from '../../MessageIdFactoryServer';

const SuperParanoidChecks = false;

export default class DatabaseUtils {
	public static readonly MaxAttemptsDefault = 5;

	public static nonBlockingUpdateDevice(
		backingStore: IBackingStore,
		deviceId: DeviceId,
		updater: UpdaterType<DeviceRecord>
	): Promise<DeviceRecord> {
		return DatabaseUtils.nonBlockingUpdate<DeviceRecord>(
			async () => (await backingStore.getDeviceByDeviceId(deviceId))!,
			updater,
			(deviceInner) => backingStore.updateDevice(deviceInner)
		);
	}

	public static nonBlockingUpdateGame(
		backingStore: IBackingStore,
		joinCode: JoinCode,
		updater: UpdaterType<Game>
	): Promise<Game> {
		return DatabaseUtils.nonBlockingUpdate<Game>(
			async () => (await backingStore.getGameByJoinCode(joinCode))!,
			updater,
			(game) => backingStore.updateGame(game)
		);
	}

	public static nonBlockingUpdateServerMessageIdRecord(
		backingStore: IBackingStore,
		updater: UpdaterType<ServerMessageIdRecord>
	): Promise<ServerMessageIdRecord> {
		return DatabaseUtils.nonBlockingUpdate<ServerMessageIdRecord>(
			backingStore.getMessageIdRecord,
			updater,
			backingStore.updateRecord
		);
	}

	/**
	 * Attempt to get, update and put a `T` in a non-blocking algorithm approach (iteratively until success).
	 * @param getItemClosure Closure that gets the item from the database.
	 * @param updateItemClosure Closure that updates the item retrieved from the database.
	 * @param putItemClosure Closure that puts the item back to the database.
	 * @param maxAttempts How many times to try before giving up.
	 */
	public static async nonBlockingUpdate<T>(
		getItemClosure: () => Promise<T>,
		updateItemClosure: (t: T) => void,
		putItemClosure: (t: T) => Promise<boolean>,
		maxAttempts: number = DatabaseUtils.MaxAttemptsDefault
	): Promise<T> {
		for (let attempt = 0; attempt < maxAttempts; attempt++) {
			Log.silly('1. Get the item');
			let t: T;
			try {
				t = await getItemClosure();
			} catch (e) {
				Log.error('Failed to get:', e);
				throw e;
			}
			// Log.silly('Got:', JSON.stringify(t));

			Log.silly('2. Update item');
			try {
				updateItemClosure(t);
			} catch (e) {
				Log.error(`nonBlockingUpdate(): updateItemClosure() failed to update ${JSON.stringify(t)}:`, e);
				throw e;
			}

			Log.silly('3. Persist to the store');
			try {
				if (await putItemClosure(t)) {
					Log.debug(`nonBlockingUpdate() succeeded on attempt ${attempt} for:`, t);
					if (SuperParanoidChecks) {
						const t2 = await getItemClosure();
						Log.debug('nonBlockingUpdate() SuperParanoidChecks after =', t2);
					}
					return t;
				}
			} catch (e) {
				Log.error(`Failed to put ${JSON.stringify(t)}:`, e);
				throw e;
			}

			// Loop until success!
		}

		const errMsg = `Failed to nonBlockingUpdate() after ${maxAttempts} so failing`;
		Log.error(errMsg);
		throw errMsg;
	}
}

export type UpdaterType<T> = (t: T) => void;

const Log = LogFactory.build('DatabaseUtils');
