import MsgResponseBase from './MsgResponseBase';
import { ResponseCode } from './ResponseCode';

/**
 * Response from a *pong* message sent from client in response to a ping.
 * Used to indicate whether the ping was received in time to retain ownership of the deviceId.
 */
export default class MsgResponsePong extends MsgResponseBase {
	public static buildSuccess(msgId: number) {
		return new MsgResponsePong(msgId, ResponseCode.Success);
	}

	public static buildError(msgId: number, responseCode: ResponseCode, errorDetails: string) {
		return new MsgResponsePong(msgId, responseCode, errorDetails);
	}

	private constructor(msgId: number, responseCode: ResponseCode, errorDetails?: string) {
		super(msgId, responseCode, errorDetails);
	}
}
