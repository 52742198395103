import { Colour } from './ColourHelperTerminal';

export class ColourHelperBrowser {
	public static getColourCode(colourFg: Colour, colourBg: Colour = Colour.Unspecified): string {
		return Colour.Unspecified === colourBg
			? `color:${Colours.get(colourFg)}`
			: `color:${Colours.get(colourFg)};background:${Colours.get(colourBg)}`;
	}
}

const Colours: Map<Colour, string> = new Map<Colour, string>([
	[Colour.Unspecified, 'Unspecified'],
	[Colour.Black, 'Black'],
	[Colour.Red, 'Red'],
	[Colour.Green, 'Green'],
	[Colour.Yellow, 'Yellow'],
	[Colour.Blue, 'DodgerBlue'],
	[Colour.Magenta, 'Magenta'],
	[Colour.Cyan, 'Cyan'],
	[Colour.White, 'White'],
]);
