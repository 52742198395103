import { ISimpleEvent, SimpleEventDispatcher } from 'strongly-typed-events';
import MsgResponseBase from '../common/messages/MsgResponseBase';
import IMessage from '../common/messages/IMessage';
import { ResponseCode } from '../common/messages/ResponseCode';
import { WebSocketCloseCode } from '../common/WebSocketCloseCode';
import { LogFactory, Logger, LogLevel } from '../common/Log';
import { DefaultSerializer } from '../common/utils/Serializer';
// Using isomorphic API
// (No need to import WebSocket when targeting the browser but when used on server...)
const WebSocket = require('isomorphic-ws');
//import WebSocket from 'isomorphic-ws'; // N.b. isomorphic-ws v5+ likely needs this but problems with message below

/**
 * Connection to the game.
 * Intention is to abstract whether done over WebSockets (as this implementation) or over WebRTC (in future).
 * `InnerMsgType` specifies the type for messages coming out of `onMessage`.  Can be omitted.
 * In CoOperation, this is generally `IMessage`.
 */
export class Connection<InnerMsgType> {
	private readonly socket: WebSocket;

	private readonly _onConnected = new SimpleEventDispatcher<Event>();

	private readonly _onMessage = new SimpleEventDispatcher<InnerMsgType>();

	private readonly _onError = new SimpleEventDispatcher<Event>();

	private readonly _onClose = new SimpleEventDispatcher<CloseEvent>();

	private readonly logger: Logger;

	public constructor(url: string, public readonly name?: string) {
		this.logger = LogFactory.build(`${Connection.name}-${name}`);
		this.socket = new WebSocket(url);
		if (!name) {
			this.name = url;
		}

		// Connection opened
		this.socket.addEventListener('open', (evt) => {
			if (this.logger.getLevel() < LogLevel.Silly) this.logger.debug('Connected');
			else this.logger.silly('Connected', evt);
			this._onConnected.dispatch(evt);
		});

		// Listen for messages
		this.socket.addEventListener('message', (evt: MessageEvent<InnerMsgType>) => {
			if (this.logger.getLevel() < LogLevel.Silly) this.logger.debug('Received message');
			else this.logger.silly('Received message:', evt.data);
			const data = this.convertData(evt.data);
			this._onMessage.dispatch(data);
		});

		this.socket.addEventListener('error', (evt) => {
			this.logger.error('Error:', evt);
			this._onError.dispatch(evt);
		});

		this.socket.addEventListener('close', (evt) => {
			if (this.logger.getLevel() < LogLevel.Silly) this.logger.debug('Closed');
			else this.logger.silly('Closed:', evt);
			this._onClose.dispatch(evt);
		});

		// These do not get delivered (at least for local WS)
		// this.socket.addEventListener('ping', (evt) => {
		// 	this.logger.log('Ping:', evt);
		// });
		//
		// this.socket.addEventListener('pong', (evt) => {
		// 	this.logger.log('Pong:', evt);
		// });
	}

	public get state(): WebSocketState {
		return this.socket.readyState;
	}

	public get onConnected(): ISimpleEvent<Event> {
		return this._onConnected.asEvent();
	}

	public get onMessage(): ISimpleEvent<InnerMsgType> {
		return this._onMessage.asEvent();
	}

	public get onError(): ISimpleEvent<Event> {
		return this._onError.asEvent();
	}

	public get onClose(): ISimpleEvent<CloseEvent> {
		return this._onClose.asEvent();
	}

	/**
	 * Succeeds when connected successfully.
	 * Also succeeds if already connected.
	 */
	public readonly succeedWhenConnected = () => this.succeedOn(SucceedOn.Connected);

	/**
	 * Succeeds when closed successfully.
	 * Also succeeds if already closed.
	 */
	public readonly succeedWhenClosed = () => this.succeedOn(SucceedOn.Close);

	/**
	 * Returns a Promise that will 'succeed' on the specified criterion and otherwise fail.
	 * @param criterion
	 */
	private succeedOn = (criterion: SucceedOn): Promise<Event> => {
		// Already in correct state?
		if (conditionMatches(criterion, this.socket.readyState))
			return Promise.resolve(new Event(eventNameFromCriterion(criterion)));

		// Otherwise an actual Promise
		return new Promise((resolve, reject) => {
			const cbConnected = (evt: Event) => {
				unsubscribeAll();
				if (SucceedOn.Connected === criterion) {
					this.logger.debug('connected = success');
					resolve(evt);
				} else {
					this.logger.debug('connected = fail');
					reject(evt);
				}
			};
			const cbError = (evt: Event) => {
				unsubscribeAll();
				if (SucceedOn.Error === criterion) {
					this.logger.debug('error (event) = success');
					resolve(evt);
				} else {
					this.logger.debug('error (event) = fail');
					reject(evt);
				}
			};
			const cbClose = (evt: CloseEvent) => {
				unsubscribeAll();
				if (SucceedOn.Close === criterion) {
					this.logger.debug('closed = success');
					resolve(evt);
				} else {
					this.logger.debug('closed = fail');
					reject(evt);
				}
			};
			this.onConnected.subscribe(cbConnected);
			this.onError.subscribe(cbError);
			this.onClose.subscribe(cbClose);
			const unsubscribeAll = (): void => {
				this.onConnected.unsubscribe(cbConnected);
				this.onError.unsubscribe(cbError);
				this.onClose.unsubscribe(cbClose);
			};
		});

		function eventNameFromCriterion(criterion: SucceedOn): string {
			switch (criterion) {
				case SucceedOn.Connected:
					return 'open';
				case SucceedOn.Close:
					return 'closed';
				case SucceedOn.Error:
					return 'error';
				default:
					throw new Error(`Unexpected criterion "${criterion}"`);
			}
		}

		function conditionMatches(criterion: SucceedOn, readyState: number): boolean {
			const toMatch: number = toWebSocketState(criterion);

			function toWebSocketState(criterion: SucceedOn): number {
				switch (criterion) {
					case SucceedOn.Connected:
						return WebSocket.OPEN;

					case SucceedOn.Close:
						return WebSocket.CLOSED;

					case SucceedOn.Error:
						return WebSocket.CLOSED; // a bit iffy
				}
			}

			return toMatch === readyState;
		}
	};

	/**
	 * Send a message to the server via a Promise.
	 * On success, the response will be the generic type.
	 * On failure, the response can be any sub-type of MsgResponseBase.
	 * @param msg Message to send.
	 * @param expectSuccess Whether to check success and fail the Promise if not.
	 *   When false, the message will be allowed through in the 'resolve' path and may not match the expected type.
	 *   2023/02/01 Default changed to `false` (previous `true` forced code to use try-catch for any failure).
	 */
	public sendToServer = async <RespType extends MsgResponseBase>(
		msg: IMessage,
		expectSuccess: boolean = false
	): Promise<RespType> => {
		return new Promise<RespType>((resolve, reject) => {
			// SEND
			const serialized = DefaultSerializer.serialize(msg);
			this.logger.debug('Preparing to send:', serialized);
			this.socket.send(serialized);

			// Would not expect result here since send will not have finished until we return
			// (Do not expect to have received a response after send() since it has not yet sent.
			this.logger.debug('Ready to send.  Setting up transient response listener.');

			const cb = (evt: MessageEvent) => {
				this.logger.debug('Received (after send):', evt.data);
				let newResponse: MsgResponseBase;
				try {
					newResponse = DefaultSerializer.deserialize(evt.data as string); // assume it's a string since that's what we send
					// TODO: Validate against a schema?!
				} catch (e) {
					this.logger.error(`Failed to deserialize: "${evt.data}"`);
					this.socket.removeEventListener('message', cb);
					reject(e); // = rejected
					return;
				}

				if (msg.msgId === newResponse.msgId) {
					this.logger.debug('Received expected response:', newResponse);
					this.socket.removeEventListener('message', cb);
					if (expectSuccess) {
						if (ResponseCode.Success !== newResponse.responseCode) {
							this.logger.warn(
								this.name,
								'Response expected success but result is',
								newResponse.responseCode,
								'so failing Promise with',
								newResponse
							);
							reject(newResponse); // Reject if failure and expected success
							return;
						}
					}
					// else
					this.logger.debug('Response is Success so resolving Promise');
					resolve(newResponse as RespType);
				} else {
					this.logger.debug('Not the response we are looking for:', newResponse);
				}
			};
			this.socket.addEventListener('message', cb);
		});
	};

	public sendTextToServer = (data: string): void => {
		this.logger.debug('Preparing to send:', data);
		this.socket.send(data);
	};

	public close = (code: WebSocketCloseCode = WebSocketCloseCode.Normal, reason?: string): Promise<Event> => {
		// check this.socket.readyState (this.socket.OPEN)
		this.logger.silly('close() socket.state:', this.socket.readyState);
		switch (this.socket.readyState) {
			case WebSocket.CLOSING:
			case WebSocket.CLOSED:
				return Promise.resolve(new Event('close'));

			case WebSocket.CONNECTING:
				throw new Error('Cannot close a socket that is still connecting!');

			case WebSocket.OPEN:
				// The good one!
				break;

			default:
				throw new Error(`Unexpected state:${this.socket.readyState}`);
		}
		const completionPromise = this.succeedWhenClosed();
		this.socket.close(code, reason);
		return completionPromise;
	};

	public toString = (): string => {
		return `${this.name} (state:${this.socket.readyState})`;
	};

	private convertData(data: any): InnerMsgType {
		switch (typeof data) {
			case 'object':
				return data;
			case 'string':
				const result = DefaultSerializer.deserialize(data);
				if ('string' === typeof result) {
					this.logger.error(`Suspicious result from DefaultSerializer.deserialize(${data}) = string '${result}'`);
				}
				return result;
			default:
				throw new Error(`Decoding ${typeof data} is not implemented for "${data}"`);
		}
	}
}

export enum SucceedOn {
	Connected = 0,
	Close = 1,
	Error = 2,
}

export enum WebSocketState {
	/** The connection is not yet open. */
	CONNECTING = 0,
	/** The connection is open and ready to communicate. */
	OPEN = 1,
	/** The connection is in the process of closing. */
	CLOSING = 2,
	/** The connection is closed. */
	CLOSED = 3,
}
