import { View, ViewManager } from './ViewManager';
import { addTouchHandlesForMouse } from './utils';
import { LogFactory, LogLevel } from '../../src-ts/common/Log';
import { ControllerState } from './ControllerState';
import { GameSession } from './GameSession';
import { JoinCode } from '../../src-ts/common/messages/JoinCode';
import KeyboardShortcuts from './KeyboardShortcuts';
import { CookieHelper } from './CookieHelper';
import { ControllerActionButton } from './ControllerActionButton';
import { CustomControllerData } from './CustomControllerData';
import { ImageURLCache } from './ImageURLCache';
import { HungKludge } from './HungKludge';
import { DeviceId } from '../../src-ts/common/messages/DeviceId';

export class App {
	/** public to allow HTML to access. */
	public readonly viewManager = new ViewManager();
	public readonly cookieHelper: CookieHelper = new CookieHelper();

	private gameSessions: GameSession[] = [];
	private _activeGameSessionInView!: GameSession;
	private keyboardController: KeyboardShortcuts;

	private _mostRecentControllerState: ControllerState | undefined;
	public get mostRecentControllerState(): ControllerState | undefined {
		return this._mostRecentControllerState;
	}

	/**
	 * Once 1 player has joined, this is the join code they used to connect,
	 * and hence the one that all subsequent players on this device must use
	 */
	private forceJoinCode?: JoinCode;

	private resettingApp: boolean = false;

	public get _sessionCount(): number {
		return this.gameSessions.length;
	}

	public static readonly MaxGameSessions: number = 8;

	public static readonly DefaultTurnsPerRound = 4;
	public static readonly MinValidTurnsPerRound = 1;
	public static readonly MaxValidTurnsPerRound = 8;

	private controllerData: CustomControllerData;

	public readonly imageURLCache: ImageURLCache = new ImageURLCache();

	private readonly hungKludge: HungKludge;

	private writerButtonsScrolling: boolean = false;
	private writerButtonsScrollTimeout: NodeJS.Timeout | undefined;

	public static readonly AllDirectionNames: string[] = ['north', 'east', 'south', 'west'];

	// Temporary hardcoded number of actions, in the future this could be moddable
	public static readonly ActionButtonCount: number = 4;

	constructor() {
		// TODO: TEMP
		LogFactory.setDefaultDefaultLevelAndAllExistingLevels(LogLevel.All);

		this.viewManager.hideAll();

		addTouchHandlesForMouse();

		// Default controller data for CO OPERATION
		this.controllerData = CustomControllerData.getDefaultControllerData();

		// Add initial GameSession
		this.resetApp();

		this.keyboardController = new KeyboardShortcuts(this);

		// Loss/gain of focus (modern version)
		document.addEventListener('visibilitychange', this.onVisibilityChange);

		// Loss/Gain of focus (old version = paranoia)
		document.addEventListener('blur', this.onBlur);
		document.addEventListener('focus', this.onFocus);

		// Intercept the "Reload" button
		window.addEventListener('beforeunload', this.onBeforeUnload, { capture: true });

		// Do not rely on 'unload' event = often not fired (especially on mobile browsers)
		window.addEventListener('unload', this.onUnload);

		this.hungKludge = new HungKludge();
	}

	public isActiveGameSessionInView = (gameSession: GameSession): boolean => {
		return gameSession === this.activeGameSessionInView;
	};

	public get activeGameSessionInView(): GameSession {
		return this._activeGameSessionInView;
	}

	public get allJoinedPlayerNames(): string[] {
		// filter defined playerNames only
		return this.gameSessions.flatMap((s) => (s.playerName ? [s.playerName] : []));
	}

	public get allSelectedCharacterIndexes(): number[] {
		return this.gameSessions.flatMap((s) => s.selectedCharacterIndex);
	}

	public setCustomControllerActionButton = (buttonIndex: number, actionButton: ControllerActionButton): void => {
		this.controllerData.setActionButton(buttonIndex, actionButton);
	};
	public resetControllerDataToDefault = (): void => {
		this.controllerData = CustomControllerData.getDefaultControllerData();
	};

	public getActionButtonData = (index: number): ControllerActionButton => {
		if (index < 0 || index >= App.ActionButtonCount) {
			throw new Error(
				`Invalid index (${index}) when trying to get action button data, expected between 0 and ${
					App.ActionButtonCount - 1
				}`
			);
		}

		if (this.controllerData.actionButtons && index < this.controllerData.actionButtons.length) {
			return this.controllerData.actionButtons[index];
		}
		return CustomControllerData.getDefaultControllerData().actionButtons![index];
	};

	public getActionNumberByName = (actionName: string, expectSuccesss: boolean = true): number => {
		const defaultControllerData = CustomControllerData.getDefaultControllerData();
		for (let i = 0; i < App.ActionButtonCount; i++) {
			let actionButtonData;
			if (this.controllerData.actionButtons && i < this.controllerData.actionButtons.length) {
				actionButtonData = this.controllerData.actionButtons[i];
			} else {
				actionButtonData = defaultControllerData.actionButtons![i];
			}
			if (actionButtonData.action === actionName) {
				return i;
			}
		}
		if (expectSuccesss) {
			throw new Error(
				`Unable to find ${ControllerActionButton.name} with action name '${actionName}' when trying to get action number`
			);
		} else {
			Log.debug(
				`Unable to find ${ControllerActionButton.name} with action name '${actionName}' when trying to get action number (not expecting success)`
			);
			return -1;
		}
	};

	// noinspection JSUnusedGlobalSymbols -- (used by HTML)
	public consumeEvent = (evt: Event): void => {
		/*evt = evt || window.event; -- latter is deprecated! */
		if ('undefined' == typeof evt.stopPropagation) {
			evt.cancelBubble = true;
		} else {
			evt.stopPropagation();
		}
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onMenuButtonPressed = (): void => {
		this.buttonPressVibration();
		this.viewManager.showGameMenu();
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onJoinButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		await this.awaitButtonPressIfNotAlready(
			'joinButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				// Use join code from HTML input field by default.  Priority order:
				// 1. From `forceJoinCode` (set by successful join by a session on this controller)
				// 2. From UI (when button pressed)
				// 3. From cookie.
				const joinParams = this.viewManager.getJoinCodeAndPlayerNameFromHTML();
				if (!joinParams.joinCode) {
					const joinCodeFromCookie = this.cookieHelper.getJoinCode();
					if (joinCodeFromCookie) joinParams.joinCode = joinCodeFromCookie;
				}
				joinParams.forceJoinCode = this.forceJoinCode;
				const result = await this.activeGameSessionInView.joinGame(joinParams);

				if (result.success) {
					this.forceJoinCode = result.joinCode;
					this.cookieHelper.setJoinCode(result.joinCode!);
					this.updateSessionsCookie();
				}
				// else wrong join code.
				// A dialog is shown elsewhere which, when dismissed, refreshes the join screen.
			}
		);
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onCancelJoinButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		await this.awaitButtonPressIfNotAlready(
			'cancelJoinButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				// Ends/closes connection of the most recent GameSession, which is
				//	expected to be the activeGameSessionInView when the Cancel button is pressed
				if (!this.isActiveGameSessionInView(this.gameSessions[this.gameSessions.length - 1])) {
					Log.error(
						'Expected activeGameSessionInView to be the most recent GameSession when calling onCancelJoinButtonPressed, but it was not'
					);
					return;
				}

				if (await this.tryEndGameSessionAtIndex(this.gameSessions.length - 1)) {
					// Session ended, show WaitOrAddPlayers view with updated player names
					this.viewManager.setupJoinedPlayersList(this.allJoinedPlayerNames, this._sessionCount);
					this.viewManager.show(View.WaitOrAddPlayers);
				} else {
					// TODO-2022/08/16: Await ignored.  Should we restructure slightly?
					this.viewManager.showInfoPopupDialog('Error', 'Failed to cancel joining game.', null);
				}
			}
		);
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onAreaOutsideMenuPressed = (): void => {
		this.buttonPressVibration();
		this.activeGameSessionInView.onCancelOrBack();
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onPlayerTabButtonPressed = (tabIndex: number): void => {
		this.buttonPressVibration();
		if (this.trySwitchActiveGameSession(tabIndex)) {
			Log.log(`Selecting player tab: ${tabIndex}`);
		}
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onSelectCharacterButtonPressed = async (
		pressedElement: HTMLElement,
		characterIndex: number
	): Promise<void> => {
		await this.awaitButtonPressIfNotAlready(
			'selectCharacterButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				await this.activeGameSessionInView.requestCharacterSelection(characterIndex);
			}
		);
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onSelectNonCharacterButtonPressed = async (
		pressedElement: HTMLElement,
		characterIndex: number
	): Promise<void> => {
		await this.awaitButtonPressIfNotAlready(
			'selectCharacterButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				await this.activeGameSessionInView.requestNonCharacterSelection();
			}
		);
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onAddPlayerButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		await this.awaitButtonPressIfNotAlready(
			'addPlayerButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				// Add player on this device: Try to add a new GameSession & make it the active one
				if (await this.tryAddGameSession()) {
					this.trySwitchActiveGameSession(this.gameSessions.length - 1);
				}
			}
		);
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onRemovePlayerButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		await this.awaitButtonPressIfNotAlready(
			'removePlayerButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				// The pressedElement is the button that triggered this function call, and
				//	its parent is the playerNameElement (which contains the button and text showing the player's name)
				const playerNameElement = pressedElement.parentNode;
				if (!playerNameElement) throw new Error('Failed to get parent node of pressedElement');
				if (!playerNameElement.parentNode) throw new Error('Failed to get parent node of playerNameElement');

				// Get the index of the playerNameElement as a child of its parent
				//	Players are listed in order of sessionIndex, so we can get that from this
				const elementIndex: number = Array.prototype.indexOf.call(
					playerNameElement.parentNode.childNodes,
					playerNameElement
				);
				// The first playerNameElement is not the first child, so we need to
				//	subtract to account for that and get a 0-based session index
				const sessionIndex: number = elementIndex - 2;

				if (sessionIndex < 0 || sessionIndex >= App.MaxGameSessions) {
					throw new Error(`Unexpected (invalid) session index when trying to remove player: ${sessionIndex}`);
				}

				// Remove player by ending the GameSession
				if (!(await this.tryEndGameSessionAtIndex(sessionIndex, true))) {
					await this.viewManager.showInfoPopupDialog('Error', 'Failed to remove player.', null);
				}
			}
		);
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onSelectTurnButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		if (!pressedElement.parentNode) {
			return;
		}
		const elementIndex: number = Array.prototype.indexOf.call(pressedElement.parentNode.childNodes, pressedElement);
		const turnNumIndex = elementIndex;
		await this.awaitButtonPressIfNotAlready(
			'selectTurnButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.activeGameSessionInView.selectATurnNumber(turnNumIndex);
				this.buttonPressVibration();
			}
		);
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onSelectActionButtonPressed = async (
		pressedElement: HTMLElement | null,
		actionNumber: number
	): Promise<void> => {
		await this.awaitButtonPressIfNotAlready(
			'selectActionButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				if (actionNumber < 0 || actionNumber >= App.ActionButtonCount) {
					Log.log(`Trying to select action with invalid action number: ${actionNumber}`);
				}
				this.buttonPressVibration();
				await this.activeGameSessionInView.selectActionNumber(actionNumber, true);
			}
		);
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onSelectDataButtonPressed = async (pressedElement: HTMLElement | null, data: string): Promise<void> => {
		await this.awaitButtonPressIfNotAlready(
			'selectDataButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				await this.activeGameSessionInView.selectDataForCurrentTurn(data, true);
			}
		);
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onReadyButtonPressed = async (pressedElement: HTMLElement | null): Promise<void> => {
		await this.awaitButtonPressIfNotAlready(
			'readyButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				await this.activeGameSessionInView.onReadyButtonPressed(true);
			}
		);
	};

	// noinspection JSUnusedGlobalSymbols -- Used by HTML
	public onUnreadyButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		await this.awaitButtonPressIfNotAlready(
			'unreadyButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				await this.activeGameSessionInView.onReadyButtonPressed(false);
			}
		);
	};

	public onItemVoteLeftButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		if (!this.activeGameSessionInView.itemManagementData) {
			return;
		}
		await this.awaitButtonPressIfNotAlready(
			'itemVoteLeftButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				this.activeGameSessionInView.itemManagementData?.decrementSelectedItemVoteIndex();
				this.activeGameSessionInView.updateItemPlacementVoteVisuals();
			}
		);
	};
	public onItemVoteRightButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		if (!this.activeGameSessionInView.itemManagementData) {
			return;
		}
		await this.awaitButtonPressIfNotAlready(
			'itemVoteRightButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				this.activeGameSessionInView.itemManagementData?.incrementSelectedItemVoteIndex();
				this.activeGameSessionInView.updateItemPlacementVoteVisuals();
			}
		);
	};
	public onItemPositionButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		if (!this.activeGameSessionInView.itemManagementData) {
			return;
		}
		if (!pressedElement.parentNode) {
			return;
		}

		const elementIndex: number = Array.prototype.indexOf.call(pressedElement.parentNode.childNodes, pressedElement);
		const positionIndex = elementIndex - 3;

		await this.awaitButtonPressIfNotAlready(
			'itemPositionButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				const itemManagementData = this.activeGameSessionInView.itemManagementData;
				const newSelectionMade = itemManagementData?.selectPositionForCurrentItem(positionIndex);
				if (newSelectionMade) {
					// Unready when selection is made
					this.activeGameSessionInView.onReadyButtonPressed(false);
				}

				this.activeGameSessionInView.updateItemPlacementVoteVisuals();
				await itemManagementData?.autoSelectNextItemWithoutPositionAfterDelay();
				this.activeGameSessionInView.updateItemPlacementVoteVisuals();
			}
		);
	};

	public onWriterCharacterButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		if (this.writerButtonsScrolling) {
			return;
		}
		await this.awaitButtonPressIfNotAlready(
			'writerCharacterButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				this.viewManager.scrollElementIntoViewCentered(pressedElement);
			}
		);
	};

	public onWriterCharacterLeftButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		const characterWritingData = this.activeGameSessionInView.characterWritingData;
		if (characterWritingData === undefined) {
			return;
		}
		await this.awaitButtonPressIfNotAlready(
			'writerCharacterLeftButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				characterWritingData.decrementSelectedCharacterIndex();
				this.viewManager.updateWriterTextInputSelectionVisuals(characterWritingData, true, false);
			}
		);
	};
	public onWriterCharacterRightButtonPressed = async (pressedElement: HTMLElement): Promise<void> => {
		const characterWritingData = this.activeGameSessionInView.characterWritingData;
		if (characterWritingData === undefined) {
			return;
		}
		await this.awaitButtonPressIfNotAlready(
			'writerCharacterRightButton',
			pressedElement,
			this.activeGameSessionInView.deviceId,
			async (): Promise<void> => {
				this.buttonPressVibration();
				characterWritingData.incrementSelectedCharacterIndex();
				this.viewManager.updateWriterTextInputSelectionVisuals(characterWritingData, true, false);
			}
		);
	};

	public onWriterCharacterSelectScroll = async (scrollElement: HTMLElement): Promise<void> => {
		this.writerButtonsScrolling = true;
		const centremost = this.viewManager.getCentremostElementInScrollView(scrollElement);
		this.viewManager.updateWriterCharacterScrollButtonsStyling(centremost.element);

		// Set a timeout to run writerCharacterScrollEnd after scrolling ends, first clearing any existing one
		// (There's currently no good/fully supported built-in event for onScrollEnd)
		if (this.writerButtonsScrollTimeout) {
			window.clearTimeout(this.writerButtonsScrollTimeout);
		}
		this.writerButtonsScrollTimeout = setTimeout(
			() => this.onWriterCharacterSelectScrollEnd(centremost.elementIndex),
			200
		);
	};

	public onWriterTextInputChanged = (textAreaElement: HTMLTextAreaElement): void => {
		if (this.activeGameSessionInView.characterWritingData === undefined) {
			return;
		}
		const characterWritingData = this.activeGameSessionInView.characterWritingData;
		characterWritingData.setTextInputForSelectedCharacter(textAreaElement.value);
		textAreaElement.value = characterWritingData.selectedCharacterText;
		this.viewManager.updateWriterTextInputSelectionVisuals(characterWritingData, false);

		const textForAllCharacters = this.activeGameSessionInView.characterWritingData.getCharacterNameToTextMap();
		this.cookieHelper.setCharacterTextForSessionIndex(this.activeGameSessionInView.sessionIndex, textForAllCharacters);
	};

	public actUponControllerStateChange = async (state: ControllerState): Promise<void> => {
		if (state === this.mostRecentControllerState) {
			return;
		}

		this.hungKludge.stopTimerAndHide();
		this.keyboardController.setActive(false);
		let view: View;
		switch (state) {
			case ControllerState.JoinScreen:
				view = View.WaitOrAddPlayers;
				break;

			case ControllerState.WaitingForSetup: {
				// End any GameSessions that did not join before setup
				await this.endGameSessionsThatHaveNotJoined();
				this.trySwitchActiveGameSession(0);
				view = View.LookAtScreen;
				this.hungKludge.startTimer();
				break;
			}

			case ControllerState.CharacterSelect:
				view = View.CharacterSelect;
				break;

			case ControllerState.WaitingForInput: {
				// Default to the first GameSession when inputting actions
				this.trySwitchActiveGameSession(0);
				this.keyboardController.setActive(true);
				if (this.activeGameSessionInView.isNonCharacterPlayer) {
					view = View.WriterTextInput;
				} else {
					view = View.GameInputPhase;
				}
				break;
			}

			case ControllerState.ItemPlacementVote:
				view = View.ItemPlacementVote;
				break;

			case ControllerState.WatchingActions:
			case ControllerState.ItemPlacementResults:
			case ControllerState.LevelFinished: // TODO-20221105: Maybe better feedback for users?
				view = View.LookAtScreen;
				this.hungKludge.startTimer();
				break;

			default:
				throw new Error(`Unhandled controller state:${state}`);
		}

		Log.log(`App actUponControllerStateChange: ${state}`);
		this.viewManager.show(view);
		this._mostRecentControllerState = state;
	};

	/**
	 * Shows the default (splash) view, ends any existing game sessions,
	 * then adds an initial one ready to allow the player to join a game,
	 * or tries to restore previous ones from cookies
	 */
	public resetApp = async (): Promise<void> => {
		if (this.resettingApp) {
			Log.warn(`Calling ${this.resetApp.name} while already resetting`);
			return;
		}

		this.resettingApp = true;

		this.viewManager.show(View.Default);

		// Reset forceJoinCode in case it was set previously
		this.forceJoinCode = undefined;

		// Ensure no active GameSessions
		await this.endAllExistingGameSessions();

		this.gameSessions = [];

		let numPreviousSessionsAdded = 0;
		const previousSessionIndicesAndNames = this.cookieHelper.getSessionsList();
		if (undefined !== previousSessionIndicesAndNames) {
			// also true when empty
			Log.debug('Found previous sessions = recreating');
			for (const previousSessionIndexAndName of previousSessionIndicesAndNames) {
				if (await this.tryAddGameSession(previousSessionIndexAndName[0], previousSessionIndexAndName[1]))
					numPreviousSessionsAdded++;
			}
			if (numPreviousSessionsAdded < previousSessionIndicesAndNames.length) {
				Log.warn(
					`Only reconnected ${numPreviousSessionsAdded} out of ${previousSessionIndicesAndNames.length} previous sessions`
				);
			}
		}

		// After restoring previous sessions, we only want to keep the ones that have joined game
		// 	(some may have been booted since we were last trying to join)
		this.endGameSessionsThatHaveNotJoined(true);

		// (Not as an `else` to above in case all previous sessions fail,
		//	or there were no restored sessions that had joined a game)
		if (0 === this._sessionCount) {
			// Add initial game session
			Log.debug('No previous sessions = creating first');
			if (!(await this.tryAddGameSession())) {
				this.resettingApp = false;
				throw new Error(`Unable to add initial ${GameSession} in resetApp()`);
			}
		}

		this.trySwitchActiveGameSession(this._sessionCount - 1);

		this.resettingApp = false;
	};

	public switchToNextNonReadyGameSession = (currentSessionIndex: number): void => {
		// Ignore (don't auto-switch to) non-character players when waiting for action input
		let ignoreNonCharacterPlayers = this.mostRecentControllerState === ControllerState.WaitingForInput;

		let nextNonReadySession: number | undefined;
		for (let i = 0; i < this.gameSessions.length; i++) {
			if (
				!this.gameSessions[i].playerIsReady &&
				(!this.gameSessions[i].isNonCharacterPlayer || !ignoreNonCharacterPlayers)
			) {
				// Found an GameSession where the player is not 'ready'
				if (i > currentSessionIndex) {
					// The session comes after the current one, let's switch to it
					nextNonReadySession = i;
					break;
				} else if (nextNonReadySession === undefined) {
					// This is the first non-ready session that was found, it will be used
					//	if none are found with a session index greater than the current one
					//	(We favour picking a session that comes after the current one, otherwise we loop back around)
					nextNonReadySession = i;
				}
			}
		}
		if (nextNonReadySession !== undefined) {
			Log.log(`Switching to the next non-ready GameSession: ${nextNonReadySession}`);
			this.trySwitchActiveGameSession(nextNonReadySession);
		} else {
			Log.log(`All players are 'ready', so not switching to another GameSession`);
		}
	};

	private trySwitchActiveGameSession = (sessionIndex: number): boolean => {
		if (sessionIndex < 0 || sessionIndex >= this.gameSessions.length) {
			Log.error(`switchActiveGameSession called with invalid sessionIndex: ${sessionIndex}`);
			return false;
		}

		let alreadyActive: boolean = this.isActiveGameSessionInView(this.gameSessions[sessionIndex]);
		Log.log(`Switching active GameSession: ${sessionIndex} (alreadyActive: ${alreadyActive})`);

		if (!alreadyActive) {
			// Sets the activeGameSessionInView and visually selects the corresponding tab
			this._activeGameSessionInView = this.gameSessions[sessionIndex];
			this.viewManager.updatePlayerTabVisuals(
				sessionIndex,
				this.activeGameSessionInView.playerName ?? '',
				this.allSelectedCharacterIndexes
			);
		}

		this.activeGameSessionInView.becameActiveSession(alreadyActive);
		return true;
	};

	private endAllExistingGameSessions = async (): Promise<void> => {
		if (!this.gameSessions || this.gameSessions.length < 1) {
			return; // No GameSessions to end
		}

		while (this.gameSessions.length > 0) {
			if (!(await this.tryEndGameSessionAtIndex(0, true))) {
				Log.error(`endAllExistingGameSessions() failed to end ${GameSession} at index 0`);
				break;
			}
		}
		Log.log('Ended all GameSessions');
	};

	/**
	 * Ends any GameSessions that were created but did not join a game
	 * e.g. This can happen if the host starts a game while someone is in the process of adding a player/session
	 */
	private endGameSessionsThatHaveNotJoined = async (allowNoSessions?: boolean): Promise<void> => {
		let sessionEnded: boolean;
		// Keep looping through gameSessions and ending any that have not joined a game
		//	until none are found. Done this way because tryEndGameSessionAtIndex manipulates the
		//	gameSessions array, so a single for loop could lead to wrong/invalid indexes being removed
		do {
			sessionEnded = false;
			for (let i = 0; i < this.gameSessions.length; i++) {
				if (!this.gameSessions[i].hasJoinedGame) {
					await this.tryEndGameSessionAtIndex(i, allowNoSessions);
					sessionEnded = true;
					break;
				}
			}
		} while (sessionEnded);
	};

	private tryAddGameSession = async (desiredSessionIndex?: number, playerName?: string): Promise<boolean> => {
		if (!this.gameSessions || this.gameSessions.length >= App.MaxGameSessions) {
			// Max number of players already joined on this device
			this.viewManager.showInfoPopupDialog('Error', 'No more players allowed on this device.', null);
			return false;
		}

		desiredSessionIndex ??= this.gameSessions.length; // default value

		let newSession: GameSession = new GameSession(
			this,
			desiredSessionIndex,
			this.viewManager,
			this.cookieHelper,
			playerName
		);
		await newSession.connectToGame();

		this.gameSessions.push(newSession);
		Log.log(`Added new GameSession ${desiredSessionIndex} (current count: ${this.gameSessions.length})`);

		newSession.onGameSessionEnded.subscribe(this.onGameSessionEnded);

		this.updateSessionsCookie();

		this.viewManager.setupJoinedPlayersList(this.allJoinedPlayerNames, this._sessionCount);

		return true;
	};

	private tryEndGameSessionAtIndex = async (
		sessionIndex: number,
		allowNoSessions: boolean = false
	): Promise<boolean> => {
		if (1 >= this.gameSessions.length && !allowNoSessions) {
			Log.error("endGameSessionAtIndex: Can't end GameSession - would result in no sessions");
			return false;
		}
		if (0 > sessionIndex || sessionIndex >= this.gameSessions.length) {
			Log.error(`endGameSessionAtIndex called with invalid playerIndex: ${sessionIndex}`);
			return false;
		}

		Log.log(`Ending GameSession at playerIndex: ${sessionIndex}`);

		// End session/close connection
		await this.gameSessions[sessionIndex].endSession();

		return true;
	};

	private onGameSessionEnded = async (sessionIndex: number): Promise<void> => {
		Log.log(`GameSession ${sessionIndex} was ended (connection closed)`);

		// Remove closed session from gameSessions array and clear subscription
		let closedSession: GameSession = this.gameSessions.splice(sessionIndex, 1)[0];
		closedSession.onGameSessionEnded.unsubscribe(this.onGameSessionEnded);

		// Ensure GameSession indexes reflect their current positions within the gameSessions array
		for (let i = 0; i < this.gameSessions.length; i++) {
			this.gameSessions[i].changeSessionIndex(i);
		}

		if (0 < this.gameSessions.length) {
			// Switch to the most recent existing GameSession in case the activeGameSessionInView was the removed one
			this.trySwitchActiveGameSession(this.gameSessions.length - 1);
		}

		this.updateSessionsCookie();

		if (this.gameSessions.length > 0) {
			// Player removed, show WaitOrAddPlayers view with updated player names
			this.viewManager.setupJoinedPlayersList(this.allJoinedPlayerNames, this._sessionCount);
		} else {
			this.cookieHelper.clearJoinCode();
			// All sessions ended, reset and go back to initial join view
			if (!this.resettingApp) {
				return this.resetApp();
			}
		}
	};

	private onWriterCharacterSelectScrollEnd = (selectedIndex: number): void => {
		this.writerButtonsScrolling = false;
		if (this.activeGameSessionInView.characterWritingData !== undefined) {
			this.activeGameSessionInView.characterWritingData.setSelectedCharacterIndex(selectedIndex);
		}
		this.viewManager.updateWriterTextInputSelectionVisuals(this.activeGameSessionInView.characterWritingData);
	};

	private updateSessionsCookie(): void {
		let list: [number, string][] = this.gameSessions
			.filter((s) => s.hasJoinedGame)
			.map((s) => [s.sessionIndex, s.playerName ?? '']);
		this.cookieHelper.setSessionsList(list);
	}

	private buttonPressVibration() {
		// window.navigator.vibrate(50);
	}

	/**
	 * Maps from UI event name + deviceId to whether it is currently being awaited.
	 * The addition of `deviceId` allows one session to use button while another is still pending.
	 * E.g. P1 chooses a character then P2 can still select one while server has not replied to P1.
	 */
	private uiEventsAwaiting: Map<string, boolean> = new Map<string, boolean>();

	// Protection from button spamming - either awaits the given funcToAwait, or skips the
	//	function call if we're still awaiting a previous button press with the same uiEventName
	private awaitButtonPressIfNotAlready = async (
		uiEventName: string,
		pressedElement: HTMLElement | null,
		deviceId: DeviceId,
		funcToAwait: () => Promise<void>
	) => {
		const key = `${uiEventName}-${deviceId}`;
		if (this.uiEventsAwaiting.get(key) ?? false) {
			Log.warn(`Skipping button press for '${uiEventName}' for device ${deviceId} (already awaited)`);
			return;
		}
		this.uiEventsAwaiting.set(key, true);
		// Apply disabledFromCode style to the element that was pressed
		//	to give players some visual feedback while awaiting
		pressedElement?.classList.add('disabledFromCode');
		await funcToAwait();
		this.uiEventsAwaiting.set(key, false);
		pressedElement?.classList.remove('disabledFromCode');
	};

	private onVisibilityChange(): void {
		Log.log('onVisibilityChange():', document.visibilityState);
	}

	private onBlur(evt: FocusEvent): void {
		Log.log('onBlur():', evt);
	}

	private onFocus(evt: FocusEvent): void {
		Log.log('onFocus():', evt);
	}

	/**
	 * Called before unload -- specifically we're using to intercept the "Reload" button.
	 */
	private onBeforeUnload(evt: BeforeUnloadEvent): string {
		Log.log('onBeforeUnload():', evt);

		// Prevent unload
		evt.preventDefault();

		// Ideally we could customise text shown 'Do you really wish to leave (leave/reload) or simply reconnect (stay/cancel)'
		// Sadly cannot seem to customise the text and using dialogs in this event is banned nowadays.
		// const answer = window.confirm('Do you wish to leave (OK) or reconnect (Cancel)?');
		// Log.log('answer:', answer);

		// TODO-20230224 Set a repeating timer to see whether canceled and try a ping?
		return (evt.returnValue = 'Do you really wish to leave (leave/reload) or simply reconnect (stay/cancel)');
	}

	/**
	 * Page is being unloaded.  Probably only fired on desktop browsers but might happen on any when reload occurring.
	 */
	private onUnload(evt: Event): void {
		Log.log('onUnload():', evt);
	}
}

const Log = LogFactory.build(App.name);

// @ts-ignore // TODO: Fix this non-reference
window.app = new App();
