import { TurnSet } from './TurnSet';

export class TurnSetWithSelections {
	public readonly turnSet: TurnSet;
	public readonly playerSelectionsMade: boolean[];

	constructor(turnSet: TurnSet, playerSelectionsMade: boolean[]) {
		this.turnSet = turnSet;
		this.playerSelectionsMade = playerSelectionsMade;
	}

	public static fromJSON(json: string): TurnSetWithSelections {
		const turnsJSON = json.substring(9, json.indexOf(`, "playerSelectionsMade"`));
		const playerSelectionsMadeJSON = json.substring(json.indexOf('playerSelectionsMade') + 22);

		const turnSetWithMethods = TurnSet.fromJSON(turnsJSON);
		const playerSelectionsMade = JSON.parse(playerSelectionsMadeJSON);

		const withMethods = new TurnSetWithSelections(turnSetWithMethods, playerSelectionsMade);
		return withMethods;
	}

	public toJSON = (): string => {
		return `"turns": ${this.turnSet.toJSON()}, "playerSelectionsMade": ${JSON.stringify(this.playerSelectionsMade)}`;
	};
}
