// Keep this synchronised with the C# version.
export enum ControllerState {
	JoinScreen,
	WaitingForSetup,
	CharacterSelect,
	WaitingForInput,
	WatchingActions,
	ItemPlacementVote,
	ItemPlacementResults,
	LevelFinished,
}
