import MsgBase from './MsgBase';
import { Command, CommandValue } from './Command';
import { ServerInfoCode } from './ServerInfoCode';
import MsgInfoFromServer from './MsgInfoFromServer';
import { DeviceId } from './DeviceId';
import IMessage, { isIMessage } from './IMessage';
import Log from '../Log';
import { toString } from '../../server/utils/JsonUtils';

export default class MsgInfoFromServerControllerDisconnected extends MsgInfoFromServer {
	public readonly command: Command = CommandValue.ServerInfoControllerDisconnected;

	/**
	 * Device that disconnected.
	 *
	 * @minimum 0
	 * @TJS-type integer
	 */
	public readonly deviceId: DeviceId;

	/**
	 * An informational message from the server indicating that a controller has disconnected.
	 *
	 * @param deviceId Which device disconnected.
	 * @param permanent Whether permanent (true) or temporary (false) disconnection.
	 * @param details Textual explanation on the situation.
	 * @param msgId Optional message Id.
	 *   Automatically generated for new messages and used to correlate with responses.
	 *   Should be unique from a given client.  Globally unique when combined with the device Id.
	 */
	public constructor(deviceId: DeviceId, permanent: boolean, details: string, msgId: number = MsgBase.MsgIdAuto) {
		super(
			permanent ? ServerInfoCode.ControllerDisconnectedPermanently : ServerInfoCode.ControllerDisconnectedTemporarily,
			details,
			msgId
		);
		this.deviceId = deviceId;
	}

	public static isValid(o: any): o is MsgInfoFromServerControllerDisconnected {
		return isIMessage(o) && CommandValue.ServerInfoControllerDisconnected === o.command;
	}

	public static buildFromIMessage(o: IMessage): MsgInfoFromServerControllerDisconnected {
		if (!MsgInfoFromServerControllerDisconnected.isValid(o)) {
			const errMsg = `Non-${MsgInfoFromServerControllerDisconnected.name} input supplied: ${toString(o)}`;
			Log.error(errMsg);
			throw new Error(errMsg);
		}
		const withMethods = new MsgInfoFromServerControllerDisconnected(o.deviceId, false, 'TO_BE_OVERWRITTEN', o.msgId);
		Object.assign(withMethods, o); // copy `o` over withMethods
		return withMethods;
	}

	toString = (): string =>
		`${this.constructor.name}(msgId:${this.msgId}, command:${this.command}, infoCode:${this.infoCode}, deviceId:${this.deviceId} details:"${this.details}")`;
}
