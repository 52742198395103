import { LogFactory } from '../Log';

export type DeviceId = string;
// N.b. changing DeviceId type requires changing schema for validation including where it's used in Messages
// (since schema is sometimes generated from these -- the JDoc).

export const NoDeviceId: DeviceId = 'NO_DEVICE_ID';

/**
 * Whether a DecideId is valid.
 * Device ids from server are strings of non-zero length.
 * See RoutingServer.spec: assertDeviceIdIsValid() for how validity is checked in tests.
 */
export function deviceIdIsValid(deviceId: DeviceId): boolean {
	Log.silly('deviceIsValid(', deviceId, ')');
	return undefined !== deviceId && NoDeviceId !== deviceId && 0 < deviceId.length;
}

const Log = LogFactory.build('DeviceId');
