/**
 * Enumeration of valid commands.
 */
export type Command =
	| CommandValue.GetDeviceId
	| CommandValue.RegisterGame
	| CommandValue.AddController
	/** Permanent disconnect. */
	| CommandValue.RemoveDevice
	/** Temporary disconnect. */
	| CommandValue.Disconnected // N.b. synthetic = not a message that is actually sent form a client.
	| CommandValue.Message
	| CommandValue.Ping
	| CommandValue.Pong
	| CommandValue.ServerInfo
	| CommandValue.ServerInfoControllerConnected
	| CommandValue.ServerInfoControllerDisconnected
	| CommandValue.ServerInfoGameConnected
	| CommandValue.ServerInfoGameDisconnected
	| CommandValue.BootDevice;
// N.b. remember to update the JSON validation to allow these new commands to come through (if not purely server-sent ones).

/**
 * Potential values for `Command` provided to unify use within code.
 * Runtime-efficient way to do enum is `const enum` but there are associated risks for distributing this TS code
 * that we shouldn't be vulnerable to (atm).  See:
 * https://www.typescriptlang.org/docs/handbook/enums.html#const-enums
 */
export const enum CommandValue {
	GetDeviceId = 'GetDeviceId',
	RegisterGame = 'RegisterGame',
	AddController = 'AddController',
	/** Permanent disconnect. */
	RemoveDevice = 'RemoveDevice',
	/** Temporary disconnect. */
	Disconnected = 'Disconnected', // N.b. synthetic = not a message that is actually sent form a client.
	Message = 'Message',
	Ping = 'Ping',
	Pong = 'Pong',
	ServerInfo = 'ServerInfo',
	ServerInfoControllerConnected = 'ServerInfoControllerConnected',
	ServerInfoControllerDisconnected = 'ServerInfoControllerDisconnected',
	ServerInfoGameConnected = 'ServerInfoGameConnected',
	ServerInfoGameDisconnected = 'ServerInfoGameDisconnected',
	BootDevice = 'BootDevice',
}
