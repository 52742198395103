/**
 * A *STRING* regular expression for a valid player name.
 * Must be a string to allow it to be used in MessageValidator.
 * Presently 2 to 32 non-JSON nor HTML chars (for now).
 * I.e. none of:
 * - HTML:
 *  - <
 *  - >
 *  - &
 *  - /
 * - JSON:
 *  - }
 *  - "
 *  - '
 *  - \
 *  - ;
 */
export const PlayerNameRegexString = '^[^;/<>&}"\'\\\\]{2,32}$';

export const PlayerNameMinLength = 2;

export const PlayerNameMaxLength = 32;
