import Log from '../Log';
import MsgBase from './MsgBase';
import IMessage, { isIMessage } from './IMessage';
import { Command, CommandValue } from './Command';
import { ConnectionId } from './ConnectionId';
import { IMessageRunContext } from '../../server/IRoutingServer';
import MsgResponseBase from './MsgResponseBase';
import { PostToConnection } from 'aws-lambda-ws-server';
import MsgResponsePong from './MsgResponsePong';
import { ResponseCode } from './ResponseCode';
import { MessageIdFactoryClient } from '../../client/MessageIdFactoryClient';
import MsgClientSentBase from './MsgClientSentBase';

export default class MsgPong extends MsgClientSentBase<MsgResponsePong> {
	public readonly command: Command = CommandValue.Pong;

	/**
	 * A 'pong' from a *client* which should result in `MsgResponsePong`.
	 * (Server responds to client `MsgPing`s via `MsgResponsePong`.)
	 *
	 * @param msgId Optional message Id.
	 *   Automatically generated for new messages and used to correlate with responses.
	 *   Should be unique from a given client.  Globally unique when combined with the device Id.
	 */
	public constructor(msgId: number = MsgBase.MsgIdAuto) {
		super(MessageIdFactoryClient.buildMsgId(MsgBase.processSuppliedMsgIdInReservedRangeIntoValue(msgId)));
	}

	public static isMsgPong(o: any): o is MsgPong {
		return isIMessage(o) && CommandValue.Pong === o.command;
	}

	public static buildFromIMessage(o: IMessage): MsgPong {
		if (!MsgPong.isMsgPong(o)) {
			const errMsg = `Non-${MsgPong.name} input supplied: ${JSON.stringify(o)}`;
			Log.error(errMsg);
			throw new Error(errMsg);
		}
		const withMethods = new MsgPong(this.MsgIdInvalidToBeOverwritten);
		Object.assign(withMethods, o); // copy `o` over withMethods
		return withMethods;
	}

	public buildSuccessResponse(): MsgResponsePong {
		return MsgResponsePong.buildSuccess(this.msgId);
	}

	public buildErrorResponse(responseCode: ResponseCode, errorDetails: string): MsgResponsePong {
		return MsgResponsePong.buildError(this.msgId, responseCode, errorDetails);
	}

	public run = async (
		connectionId: ConnectionId,
		context: IMessageRunContext,
		postToConnection: PostToConnection
	): Promise<MsgResponseBase> => {
		Log.debug(`[${this.msgId}] ConnectionId:${connectionId} PONG`);
		const success = await context.operations.pongReceivedFrom(connectionId, this.msgId);
		return success
			? MsgResponsePong.buildSuccess(this.msgId)
			: MsgResponsePong.buildError(
					this.msgId,
					ResponseCode.PongTooLate,
					'Too slow to respond to ping = connection lost.'
			  );
	};

	toString = (): string => `${this.constructor.name}(msgId:${this.msgId}, command:${this.command})`;
}
