import MsgResponseBase from './MsgResponseBase';
import { ResponseCode } from './ResponseCode';

export default class MsgDisconnectDevicePermanentlyResponse extends MsgResponseBase {
	public static buildSuccess(msgId: number) {
		return new MsgDisconnectDevicePermanentlyResponse(msgId, ResponseCode.Success);
	}

	public static buildError(msgId: number, responseCode: ResponseCode, errorDetails: string) {
		return new MsgDisconnectDevicePermanentlyResponse(msgId, responseCode, errorDetails);
	}

	private constructor(msgId: number, responseCode: ResponseCode, errorDetails?: string) {
		super(msgId, responseCode, errorDetails);
	}
}
