export enum ResponseCode {
	/** Should not be used since suggests uninitialised value. */
	UnknownDoNotUse = 0,

	/** Success, duh. */
	Success = 1,

	/** Failure of the operation requested. */
	Failure = 2,

	/**
	 * This error code should really be more specific.
	 * Should only be used until a more specific error code is added.
	 */
	OtherError = 3,

	/** Any parsing or decoding error (such as JSON parsing). */
	ObjectDecodingError = 4,

	/** The join code supplied does not connect to a game. */
	JoinCodeInvalid = 5,

	/** The join code supplied does not work (perhaps the game is full?). */
	JoinCodeNotAllowed = 6,

	/** No connection to the target available (perhaps the client has disconnected?). */
	ConnectionUnavailable = 7,

	/** Device id not known! */
	DeviceIdNotKnown = 8,

	/** Ping-pong response received after timeout. */
	PongTooLate,
}

export function ResponseCodeToName(code: ResponseCode): string {
	return ResponseCode[code];
}
export function ResponseCodesToNames(...codes: ResponseCode[]): string {
	return '[' + codes.map((c) => `${ResponseCodeToName(c)}:${c}`).join(', ') + ']';
}
