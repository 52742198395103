export enum ServerInfoCode {
	/** Should not be used since suggests uninitialised value. */
	UnknownDoNotUse = 0,

	/** New connection info. */
	Connected = 1,

	/** Controller connected. */
	ControllerConnected = 2,

	/** Controller disconnected permanently. */
	ControllerDisconnectedPermanently = 3,

	/** Game disconnected permanently. */
	GameDisconnectedPermanently = 4,

	/** Controller disconnected temporarily. */
	ControllerDisconnectedTemporarily = 5,

	/** Game disconnected temporarily. */
	GameDisconnectedTemporarily = 6,

	/** Controller reconnected. */
	ControllerReconnected = 7,

	/** Game reconnected. */
	GameReconnected = 8,

	// N.b. Be sure to keep this in-sync with the C# version!
}

export function ServerInfoCodeToName(code: ServerInfoCode): string {
	return ServerInfoCode[code];
}

export function ServerInfoCodesToNames(...codes: ServerInfoCode[]): string {
	return '[' + codes.map((c) => `${ServerInfoCodeToName(c)}:${c}`).join(', ') + ']';
}
