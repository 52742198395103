import { Connection, WebSocketState } from '../../src-ts/client/Connection';
import MsgAddController from '../../src-ts/common/messages/MsgAddController';
import IMessage from '../../src-ts/common/messages/IMessage';
import { LogFactory, Logger } from '../../src-ts/common/Log';
import { JoinCode, joinCodeIsValid, NoJoinCode } from '../../src-ts/common/messages/JoinCode';
import { ISimpleEvent, SimpleEventDispatcher } from 'strongly-typed-events';
import { ResponseCode, ResponseCodeToName } from '../../src-ts/common/messages/ResponseCode';
import MsgResponseBase from '../../src-ts/common/messages/MsgResponseBase';
import MsgGetDeviceId from '../../src-ts/common/messages/MsgGetDeviceId';
import MsgGetDeviceIdResponse from '../../src-ts/common/messages/MsgGetDeviceIdResponse';
import { DeviceId, deviceIdIsValid } from '../../src-ts/common/messages/DeviceId';
import MsgMessage from '../../src-ts/common/messages/MsgMessage';
import MsgInfoFromServer from '../../src-ts/common/messages/MsgInfoFromServer';
import { TurnSet } from './TurnSet';
import IResponse from '../../src-ts/common/messages/IResponse';
import { WebSocketCloseCode } from '../../src-ts/common/WebSocketCloseCode';
import { toString } from '../../src-ts/server/utils/JsonUtils';
import MsgPong from '../../src-ts/common/messages/MsgPong';
import MsgDisconnectDevicePermanently from '../../src-ts/common/messages/MsgDisconnectDevicePermanently';
import { CommandValue } from '../../src-ts/common/messages/Command';
import { CharacterWritingData } from './CharacterWritingData';
import { ItemManagementData } from './ItemManagementData';
import assert = require('assert');

/**
 * Proxies the actual game for this browser.
 * Interacts with the actual game.
 */
export class GameProxy {
	/**
	 * Construct a new GameProxy.
	 * @param desiredDeviceId Optionally request this `DeviceId`.
	 * @param previousJoinCode Optionally provide the joinCode.
	 * 		Needed when reconnecting with a game for the MsgMessages sent to be valid.
	 * @param sessionIndex The index of this session (for logging).
	 * @param wsUrl The WebSocketServer URL to connect to.
	 */
	constructor(
		desiredDeviceId?: DeviceId,
		previousJoinCode?: JoinCode,
		private readonly sessionIndex?: number,
		wsUrl?: string
	) {
		this.logger = LogFactory.build(`${GameProxy.name}(s${sessionIndex})`);
		if (undefined !== wsUrl) {
			this.wsUrl = wsUrl;
		} else if (undefined !== WsUrlDefault && '' !== WsUrlDefault) {
			this.wsUrl = WsUrlDefault;
		} else {
			const errMsg = 'ERROR: environmental variable `MFG_WSS` not defined at build time to tell us WSS server to use!';
			this.logger.error(errMsg);
			throw new Error(errMsg);
		}

		if (undefined !== desiredDeviceId) {
			this.logger.log(`Will attempt to use previous deviceId:${desiredDeviceId}`);
			this.previousDeviceId = this._deviceId = desiredDeviceId;
		}
		if (undefined !== previousJoinCode) {
			this.logger.log(`Will attempt to use previous joinCode:${previousJoinCode}`);
			this.setCurrentJoinCode(previousJoinCode);
		}
		// ParcelJS Hot module reloading = only during development
		// See https://parceljs.org/features/development/
		if (module.hot) {
			this.logger.debug('Registering ParcelJS module reloading handlers');
			module.hot.dispose((data: any) => {
				this.logger.warn('ParcelJS Hot Module Reload = dispose()');
				return this.closeConnection(
					false,
					WebSocketCloseCode.ClientConnectionTimeoutRetrying,
					'ParcelJS reload (GameProxy)'
				);
			});
			module.hot.accept(() => {
				this.logger.warn(`ParcelJS Hot Module Reload = accept() (reload) state:${this.stateName}`);
				if (this.state !== State.Connected) return;

				return this.closeConnection(
					false,
					WebSocketCloseCode.ClientConnectionTimeoutRetrying,
					'ParcelJS reloaded (GameProxy)'
				);
			});
		}
	}

	/**
	 * Dispatched for connection established/closed/errored events from the server.
	 * Reconnection is handled automatically so this is mostly for UI updates.
	 */
	public get onConnectionEvent(): ISimpleEvent<Event | CloseEvent> {
		return this._onConnectionEvent.asEvent();
	}

	public get onMsgMessage(): ISimpleEvent<MsgMessage> {
		return this._onMsgMessage.asEvent();
	}

	public get onMsgServerInfo(): ISimpleEvent<MsgInfoFromServer> {
		return this._onMsgServerInfo.asEvent();
	}

	public get onDeviceIdAcquired(): ISimpleEvent<DeviceId> {
		return this._onDeviceIdAcquired.asEvent();
	}

	public get onJoinCodeSet(): ISimpleEvent<JoinCode> {
		return this._onJoinCodeSet.asEvent();
	}

	public get deviceId(): DeviceId | undefined {
		return this._deviceId;
	}

	public get state(): State {
		return this._state;
	}

	public get stateName(): string {
		return StateToName(this._state);
	}

	public get didRejoinPreviousSession(): boolean {
		return this.previousDeviceId === this._deviceId;
	}

	public connect = (): Promise<void> => {
		// If not NotConnected (which means first connection)
		// and not AwaitingReconnect (which means we haven't yet started reconnecting)
		// THEN we skip connecting.  The implication is we already started reconnecting!
		if (State.NotConnected !== this._state && State.AwaitingReconnect !== this._state) {
			this.logger.silly('already connecting');
			return Promise.resolve();
		}

		this.reconnectCount++;
		return new Promise((resolve) => {
			this.logger.log(`Connecting to server (from state:${this._state}, reconnectNum:${this.reconnectCount})`);
			// Tidy previous connection if set
			if (this.connection) {
				// TODO-20230106: Should we (a) test connection is disconnected? (we'd need to add status checking code)
				this.connection.onConnected.unsubscribe(this.onConnectedEvent);
				this.connection.onMessage.unsubscribe(this.onMessage);
				this.connection.onClose.unsubscribe(this.onConnectionCloseOrErrorEvent);
				this.connection.onError.unsubscribe(this.onConnectionCloseOrErrorEvent);
			}

			this._state = State.Connecting;

			// Try initial connection.  Most of the time, this will succeed.
			// DNS problems can result in hang.  Network issues can result in error.
			// When the event occurs (close/error), it is received and reconnect attempted.

			this.logger.silly('connect(): START');
			if (!this.firstPromiseResolve) {
				this.logger.log('First time connection = promise stashed to resolve later');
				this.firstPromiseResolve = resolve;
			}

			this.connection = new Connection<IMessage>(
				this.wsUrl,
				`(s${this.sessionIndex},c${this.reconnectCount})(${this.wsUrl})`
			);

			this.connection.onConnected.one(async (evt) => {
				this._state = State.Connected;
				this.logger.silly('connect(): CONNECTED. evt:', evt);
				this.logger.log(`Connected ${this.connection?.name}.`);

				// We get new deviceId or reconnect with our previous one
				// This will throw Error if failure but we let that cancel everything and fall out
				await this.getDeviceIdNewOrExisting();
				if (undefined === this.deviceId) {
					const errMsg = 'No deviceId found after getDeviceIdNewOrExisting()';
					this.logger.error(errMsg);
					throw new Error(errMsg);
				}

				this._onDeviceIdAcquired.dispatch(this.deviceId);

				// Make sure we use the original resolve if such we have
				const resolveToUse = this.firstPromiseResolve ?? resolve;
				this.firstPromiseResolve = undefined;
				// We'll do the onConnected Event before resolving the Promise in hopes of making user life easier (but maybe it would be OK after?)
				this.onConnectedEvent(evt);
				resolveToUse();
			});

			// 2. Ongoing registration to detect future failure and inform the game
			this.connection.onClose.subscribe(this.onConnectionCloseOrErrorEvent);
			this.connection.onError.subscribe(this.onConnectionCloseOrErrorEvent);

			// 3. Ongoing registration for messages
			this.connection.onMessage.subscribe(this.onMessage);
		});
	};

	public closeConnection = async (
		sendPermanentDisconnectLeaveGame: boolean = false,
		code: WebSocketCloseCode = WebSocketCloseCode.Normal,
		reason?: string
	): Promise<void> => {
		this._state = State.Closing;

		// If timer for reconnect, cancel it!
		if (this.reconnectTimer) {
			this.logger.log('Cancelling reconnect timer');
			clearTimeout(this.reconnectTimer);
			this.reconnectTimer = undefined;
		}

		if (!this.connection) {
			this.logger.silly('No connection so skipping closing.');
			this._state = State.Closed;
			return;
		}

		if (sendPermanentDisconnectLeaveGame) {
			this.logger.log(`Permanently (leaving game) disconnecting ${this.connection}`);
			await this.connection.sendToServer(new MsgDisconnectDevicePermanently());
		}

		this.logger.log(`closing ${this.connection}`);

		this.connection.onClose.clear();
		this.connection.onError.clear();
		this.connection.onMessage.clear();
		this.connection.onConnected.clear();

		// Copy and wipe before closing so nothing tries to send after here.
		const connection = this.connection;
		this.connection = undefined;
		try {
			await connection.close(code, reason);
			this._state = State.Closed;
			this.logger.silly('closeConnection(): CLOSED');
		} catch (e) {
			this.logger.warn('Closing caused error:', e);
		}
	};

	/**
	 * Get a deviceId.
	 * If we already have one (`this._deviceId` set), we re-request that;
	 * Otherwise we get a fresh one.
	 *
	 * Leaves dispatching `_onDeviceIdAcquired` to caller.
	 *
	 * Throws error on failure to allow caller to handle.
	 */
	private getDeviceIdNewOrExisting = async (): Promise<void> => {
		assert(this.connection);
		this.logger.log(`Getting deviceId: ${this._deviceId ?? '(new)'}...`);
		let errMsg: string;
		try {
			assert(this.connection);
			this.logger.silly('getDeviceIdNewOrExisting() current deviceId:', this._deviceId);
			const response = await this.connection.sendToServer<MsgGetDeviceIdResponse>(new MsgGetDeviceId(this._deviceId));
			if (ResponseCode.Success === response.responseCode) {
				// Success
				if (this._deviceId) {
					// Success with previously-requested deviceId
					if (this._deviceId === response.deviceId) {
						this.logger.log(
							`Successfully reconnected with deviceId:${this.deviceId} (previous:${this.previousDeviceId} = updating to new now)`
						);
						// Reset previous to this new one so future reconnects (checking `didRejoinPreviousSession`) know we reconnected
						this.previousDeviceId = response.deviceId;
						return;
					} else {
						if (deviceIdIsValid(response.deviceId)) {
							this.logger.warn(
								`Failed to reconnect with previous deviceId (was "${this._deviceId}", now "${response.deviceId}")`
							);
							this._deviceId = response.deviceId;
							// TODO-20230209: Controller should handle failure to reconnect with previous deviceId!
							return;
						} else {
							errMsg = `Received invalid deviceId:"${response.deviceId}"`;
							this.logger.warn(errMsg);
						}
					}
				} else {
					// Success with no previously-requested deviceId
					if (deviceIdIsValid(response.deviceId)) {
						this._deviceId = response.deviceId;
						// (not assigning `previousDeviceId` here since that tells `didRejoinPreviousSession` that we rejoined and don't need certain info)
						this.logger.log(`Successfully got new deviceId ${this.deviceId}`);
						return;
					} else {
						errMsg = `Received invalid deviceId:"${response.deviceId}"`;
						this.logger.warn(errMsg);
					}
				}
			} else {
				// Failure getting DeviceId! (with or without requesting one)
				this.logger.error(`Failed to get deviceId (leaving current deviceId ${this._deviceId}): ${toString(response)}`);
				errMsg = 'Failed to get deviceId = retry';
			}
		} catch (e) {
			// Error getting DeviceId! (with or without requesting one)
			this.logger.error(`Error getting deviceId: ${toString(e)}`);
			errMsg = 'Error getting deviceId = retry';
		}

		throw new Error(errMsg);
	};

	/**
	 * Record the Game's join code for use in subsequent messages
	 */
	public setCurrentJoinCode = (newJoinCode: JoinCode): void => {
		this.logger.log(`Setting joinCode:'${newJoinCode}'`);
		assert.ok(joinCodeIsValid(newJoinCode), newJoinCode);
		this.joinCode = newJoinCode;
		this._onJoinCodeSet.dispatch(newJoinCode);
	};

	public sendTurnSetAndReady = async (turnSet: TurnSet): Promise<void> => {
		this.logger.log(`Sending ${turnSet}`);
		const data = `{
	"commands": [ 
		{
			"command": "MultiTurn.setTurns",
			"turns":${turnSet.toJSON()}
		},
		{
			"command": "MultiTurn.ready",
			"ready": true,
		}
	]
}`;
		await this.sendToGameAndWait(data);
	};

	public sendItemManagementResultsAndReady = async (
		itemManagementData: ItemManagementData,
		characterIndex: number
	): Promise<void> => {
		const data = `{
	"command": "MultiTurn.controllerModMessage",
	"controller.itemVoteResults": "",
	${itemManagementData.itemsToJSON()}
	"characterIndex": ${characterIndex}
}`;
		await this.sendToGameAndWait(data);
		await this.sendReadyState(true);
	};

	public sendNarrativeCharacterText = async (characterWritingData: CharacterWritingData): Promise<void> => {
		const data = {
			command: 'MultiTurn.controllerModMessage',
			'controller.narrativeCharacterText': '',
			characterInternalName: characterWritingData.selectedCharacterInternalName,
			characterDisplayName: characterWritingData.selectedCharacterDisplayName,
			mainText: characterWritingData.selectedCharacterText,
		};
		await this.sendToGameAndWait(JSON.stringify(data));
	};

	public sendCharacterSelectRequest = async (characterIndex: number): Promise<void> => {
		this.logger.log(`Requesting character selection: ${characterIndex}`);

		const data = `{
	"meta.select.requestCharacter": "${characterIndex}"
}`;
		await this.sendToGameAndWait(data);
	};

	public sendNonCharacterSelectRequest = async (): Promise<void> => {
		this.logger.log(`Requesting selection for any available non-character`);

		const data = `{
	"meta.select.requestNonCharacter": ""
}`;
		await this.sendToGameAndWait(data);
	};

	public sendReadyState = async (ready: boolean): Promise<void> => {
		const readyData = `{
	"command": "MultiTurn.ready",
	"ready": ${ready},
}`;
		await this.sendToGameAndWait(readyData);
	};

	public joinGame = async (joinCode: JoinCode, playerName: string): Promise<void> => {
		assert(this.connection);
		this.logger.log('Registering controller:');
		let response: MsgResponseBase;
		try {
			response = await this.connection.sendToServer(new MsgAddController(joinCode, playerName));
		} catch (e) {
			if (!e) {
				throw new JoinError(ResponseCode.OtherError, 'Unknown error!');
			} else if ('object' === typeof e) {
				// && e instanceof MsgAddControllerResponse) { // TODO: Or it might be a MsgResponseConnectionIdNotFound
				const errResponse = e as IResponse;
				throw new JoinError(errResponse.responseCode, errResponse.errorDetails);
			} else {
				throw e;
			}
		}

		this.logger.log('Register controller response:', response);
		if (ResponseCode.Success !== response.responseCode) {
			throw new JoinError(response.responseCode, response.errorDetails);
		}

		// else success
	};

	public sendToGameAndWait = async (
		data: string,
		whileWaiting: WhileWaiting = WhileWaiting.DisableInput
	): Promise<void> => {
		assert(this.connection, 'No connection'); // TODO-20240424 this error does not appear.  Instead get `{"generatedMessage":false,"code":"ERR_ASSERTION","expected":true,"operator":"=="}`
		this.logger.log(`Sending data: ${data}`);
		this.doWhileWaiting(true, whileWaiting);
		let response: MsgResponseBase;
		try {
			assert(joinCodeIsValid(this.joinCode), this.joinCode);
			response = await this.connection.sendToServer(new MsgMessage(data, this.joinCode));
		} catch (e) {
			if (!e) {
				const errMsg = `Unknown error sending data:${data}`;
				this.logger.log(errMsg);
				throw new MsgError(ResponseCode.OtherError, errMsg);
			} else if ('object' === typeof e) {
				const errResponse = e as IResponse;
				const errMsg = `${errResponse.errorDetails} while sending data:${data}`;
				this.logger.log(errMsg);
				throw new MsgError(errResponse.responseCode, errMsg);
			} else {
				this.logger.log(e);
				throw e;
			}
		} finally {
			this.doWhileWaiting(false, whileWaiting);
		}

		// (async response received from send)
		this.logger.log(`Response to data:${data} :`, response);
		if (ResponseCode.Success !== response.responseCode) {
			throw new MsgError(response.responseCode, response.errorDetails);
		}

		// else success
	};

	private doWhileWaiting(enableNotDisable: boolean, whileWaiting: WhileWaiting): void {
		switch (whileWaiting) {
			case WhileWaiting.DisableInput:
				// TODO: Disable further input if enableNotDisable and re-enable otherwise
				this.logger.log(enableNotDisable ? 'input disabled' : 'input re-enabled');
				break;
			case WhileWaiting.DoNothing:
				break;
		}
	}

	private onConnectionCloseOrErrorEvent = (evt: Event | CloseEvent): void => {
		// Not an error -- just informative since we will try to reconnect
		this.logger.log('Closed:', evt, 'connection:', this.connection);

		if (undefined === this.connection) {
			this.logger.log('Reconnection already scheduled');
			return;
		}

		switch (this.connection.state) {
			case WebSocketState.OPEN:
			case WebSocketState.CONNECTING:
				this.logger.log('already reconnecting/reconnected');
				return;
		}

		switch (this._state) {
			case State.Closing:
			case State.Closed:
				this.logger.log('Instructed to close so not reconnecting');
				return;
		}

		this.logger.log(`Reconnecting automatically in ${this.pauseBetweenReconnects}ms for ${this.connection}`);
		this.connection = undefined;
		this._state = State.AwaitingReconnect;
		assert(!this.reconnectTimer);
		this.reconnectTimer = setTimeout(async () => {
			this.reconnectTimer = undefined;
			if (State.AwaitingReconnect !== this._state) {
				this.logger.log(`On reconnect timer elapsed, state:${this._state} so skipping reconnect.`);
				return;
			}

			this.logger.log(`Attempting reconnect (${this.pauseBetweenReconnects}ms delay has elapsed)`);
			await this.connect();
		}, this.pauseBetweenReconnects);

		this._onConnectionEvent.dispatch(evt);
	};

	private onConnectedEvent = (evt: Event): void => {
		this._state = State.Connected;
		this.logger.silly('onConnectedEvent():', evt);
		this._onConnectionEvent.dispatch(evt);
	};

	private onMessage = async (msg: IMessage | IResponse) => {
		if ('command' in msg) {
			switch (msg.command) {
				case CommandValue.Message:
					const msgMessage: MsgMessage = MsgMessage.buildFromIMessage(msg);
					if (this.isOutOfDate(msgMessage)) {
						this.logger.warn(`Ignoring out-of-date message:${msgMessage}`);
						return;
					}
					this._onMsgMessage.dispatch(msgMessage);
					break;

				case CommandValue.ServerInfo:
					const msgServerInfo: MsgInfoFromServer = MsgInfoFromServer.buildFromIMessage(msg);
					this._onMsgServerInfo.dispatch(msgServerInfo);
					break;

				case CommandValue.Ping:
					await this.sendPong(msg.msgId);
					break;

				case CommandValue.GetDeviceId:
				case CommandValue.RegisterGame:
				case CommandValue.AddController:
				case CommandValue.ServerInfoControllerConnected:
				case CommandValue.ServerInfoControllerDisconnected:
				case CommandValue.ServerInfoGameConnected: // TODO: Use this to resend previously failed message?
				case CommandValue.ServerInfoGameDisconnected:
					// nop
					break;

				default:
					const errMsg = `onMessage() - unhandled message Command type: ${msg.command} (msg id: ${msg.msgId})`;
					this.logger.error(errMsg);
					throw new Error(errMsg);
			}
		} else if ('responseCode' in msg) {
			this.logger.debug('onMessage() received response (hopefully handled by sendToGameAndWait()):', msg);
		} else if ('msgId' in msg) {
			const msgId = msg['msgId'];
			// msg has msgId but no command
			// Happens when msg implements IResponse rather than IMessage (should onMessage be receiving these?)
			this.logger.warn(`onMessage() msg has msgId (${msgId}) but no command:`, msg);
		} else {
			// msg has no command or msgId
			throw new Error(`onMessage() - unexpected type for msg parameter: ${typeof msg}: ${toString(msg)}`);
		}
	};

	private sendPong = async (msgId: number): Promise<void> => {
		const loggerName = 'sendPong()';
		this.logger.log(loggerName);
		assert(this.connection, 'No connection');
		let response: MsgResponseBase;
		try {
			response = await this.connection!.sendToServer(new MsgPong(msgId));
		} catch (e) {
			const errMsg = `Failed responding to ping:${toString(e)}`;
			this.logger.error(errMsg);
			throw new Error(errMsg);
		}

		switch (response.responseCode) {
			case ResponseCode.Success:
				this.logger.log(loggerName, 'successfully responded to ping');
				break;

			case ResponseCode.Failure:
				this.logger.warn('We were too slow to respond to ping and lost our deviceId.  Need to reconnect');
				// TODO-20230216: Handle lost deviceId
				break;

			default:
				const errMsg = `Pong resulted in unexpected response:${ResponseCodeToName(response.responseCode)}`;
				this.logger.error(errMsg);
				throw new Error(errMsg);
		}
	};

	/** Ignore out of date messages. */
	private isOutOfDate = (msgMessage: MsgMessage): boolean => {
		// Server sent messages (negative message Ids) are not considered outdated by this code.
		if (0 > msgMessage.msgId) {
			return false;
		}

		this.logger.debug('isOutOfDate() checking: ', msgMessage);

		let data: any;
		try {
			data = msgMessage.getDataDecoded();
		} catch (e) {
			this.logger.warn(`Failed to decode message data:${e}`);
			return false;
		}

		const topic: string | undefined = data[MsgMessage.MsgTopicName];
		if (undefined === topic) {
			return false;
		}

		this.logger.debug('isOutOfDate() topic:', topic, 'msgId:', msgMessage.msgId, 'data:', data);
		const greatest: number = this.greatestMessageMsgIdSeen.get(topic) ?? 0;
		// We consider duplicate (=) messages to also be out of date (since seen already).
		if (msgMessage.msgId <= greatest) {
			return true;
		}

		this.greatestMessageMsgIdSeen.set(topic, msgMessage.msgId);
		return false;
	};

	/**
	 *  The greatest msgId seen so far for a MsgMessage with a given topic.
	 * Used to ignore 'older' messages (only with same topic).
	 * @private
	 */
	private readonly greatestMessageMsgIdSeen: Map<string, number> = new Map<string, number>();

	private readonly _onConnectionEvent = new SimpleEventDispatcher<Event | CloseEvent>();

	private readonly _onMsgMessage = new SimpleEventDispatcher<MsgMessage>();

	private readonly _onMsgServerInfo = new SimpleEventDispatcher<MsgInfoFromServer>();

	private readonly _onDeviceIdAcquired = new SimpleEventDispatcher<DeviceId>();

	private readonly _onJoinCodeSet = new SimpleEventDispatcher<JoinCode>();

	/**
	 * Last join code used.
	 * Used when sending messages to ensure they reach the correct game!
	 */
	private joinCode: JoinCode = NoJoinCode;

	private _deviceId?: DeviceId;

	/** The connection (when connected). */
	private connection: Connection<IMessage> | undefined;

	private reconnectCount: number = 0;

	private _state: State = State.NotConnected;

	/** When defined, holds the 'handle' for canceling the reconnect timer. */
	private reconnectTimer?: ReturnType<typeof setTimeout>;

	/** The 'resolve' callback for the external call to `connect()`.
	 * If already defined in connect, is used to 'complete' the Promise.
	 */
	private firstPromiseResolve?: () => void;

	/**
	 * DeviceId loaded from cookie from previous session.
	 * @private
	 */
	private previousDeviceId: DeviceId | undefined;

	private readonly wsUrl: string;

	private readonly pauseBetweenReconnects: number = 500;

	private readonly logger: Logger;
}

// N.b. Parcel inlines the value at compile-time so the browser runs against the correct WebSocket server.
const WsUrlDefault: string | undefined = process.env.MFG_WSS;

class JoinError extends Error {
	constructor(public readonly responseCode: ResponseCode, message?: string) {
		super(message);
	}
}

class MsgError extends Error {
	constructor(public readonly responseCode: ResponseCode, message?: string) {
		super(message);
	}
}

enum WhileWaiting {
	DoNothing,
	DisableInput,
}

export enum State {
	/** Between being created and asked to initially connect. */
	NotConnected,
	/** When connecting, both initially and after any non-explicit disconnections. */
	Connecting,
	/** When connected. */
	Connected,
	/** Only when explicitly asked to close. */
	Closing,
	/** Only when explicitly closed. */
	Closed,
	/** When scheduled reconnect (after pause) but not yet started. */
	AwaitingReconnect,
}

export function StateToName(s: State): string {
	return State[s];
}
export function StatesToNames(...states: State[]): string {
	return '[' + states.map((c) => `${StateToName(c)}:${c}`).join(', ') + ']';
}
