import IMessage from './IMessage';
import { Command } from './Command';

export default abstract class MsgBase implements IMessage {
	/**
	 * Message identifier that is unique to this message *for this source*.
	 * (There may be duplicate values from other sources.)
	 * Positive values indicate messages from clients whereas negative values indicate messages from a/the server.
	 *
	 * @TJS-type integer
	 */
	public msgId: number;

	public abstract readonly command: Command;

	public static idIsOutsideReservedRange(msgId: number): boolean {
		// outside reserved range, return as is
		return msgId < MsgBase.MsgReservedMin || MsgBase.MsgReservedMax < msgId;
	}

	protected constructor(msgId: number) {
		this.msgId = msgId;
	}

	protected static processSuppliedMsgIdInReservedRangeIntoValue(msgId: number): number {
		// outside reserved range, return as is
		if (this.idIsOutsideReservedRange(msgId)) return msgId;

		switch (msgId) {
			case MsgBase.MsgIdInvalidToBeOverwritten:
			case MsgBase.NoMsgIdMeansError:
				// Known special values get returned as is
				return msgId;

			case MsgBase.MsgIdAuto:
			default:
				// auto and default get forced to auto
				return MsgBase.MsgIdAuto;
		}
	}

	toString = (): string => `${this.constructor.name}(msgId:${this.msgId}, command:${this.command})`;

	public static readonly MsgIdAuto = 0;

	protected static readonly MsgIdInvalidToBeOverwritten = -1;

	public static readonly NoMsgIdMeansError = -2;

	public static readonly MsgReservedMin = -8;
	public static readonly MsgReservedMax = 8;
}
